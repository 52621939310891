<template>
  <v-container fluid ref="messagerie-container" :class="{
    'hotline-container': !detailledView,
    'hotline-container-detailled': detailledView,
  }">
    <form-fiche v-if="selectedFiche == null && createFicheForm && detailledView" @send="sendFiche" :impacts="impacts"
      :modules="allModules" :subModules="subModules" :type_demandes="type_demandes" :ordre_priorites="ordre_priorites"
      :bases_clientsArraySelectable="bases_clientsArraySelectable" :keyLangCheat="keyLangCheat"
      :detailledView="detailledView"></form-fiche>
    <form-settings v-if="selectedFiche == null && viewSettings" :detailledView="detailledView"></form-settings>
    <!-- chat grand écran -->
    <chat v-if="
      selectedFiche != null &&
      !createFicheForm &&
      detailledView &&
      showSelectedDetailled
    " @close-chat="selectedFiche = null" @change-content="(text) => (content = text)" :files="files"
      :optionsScrollChat="optionsScrollChat" :keyLangCheat="keyLangCheat" :selectedFiche.sync="selectedFiche"
      :modules="modules" :main_hotline_base="main_hotline_base" :user="user" :rightsUser="rightsUser" :users="users"
      :impacts="impacts" :ordre_priorites="ordre_priorites" :etats="etats" :type_demandes="type_demandes"
      :tagsPlaneteOnline="tagsPlaneteOnline" :messagesInWritings="messagesInWritings" :userId="userId" :socket="socket"
      :informations="informations" :detailledView="detailledView" :ref="chatComponent" />
    <div class="card-one fill-height" v-if="detailledView && !showSelectedDetailled && !createFicheForm">
      <messagerie-header :filterType="filterType" :viewExport="viewExport" :tokenRights="token_rights" :bases="bases"
        :filter_simple.sync="filter_simple" :filters_simple="filters_simple"
        :filterAdvancedActivate="filterAdvancedActivate" :arrayFilterChecked="arrayFilterChecked"
        :filterUser="filterUser" :detailledView="detailledView" :rightsUser="rightsUser" :fichesObj="fiches"
        :usersObj="users" :filtre_users="filtre_users" :isFilterVselect="isFilterVselect"
        :tagsPlaneteOnline="tagsPlaneteOnline" :main_hotline_base="main_hotline_base" />

      <div class="d-flex flex-row align-center mb-3 filter-container ">
        <search-fiche-ticket :searchTicket="searchTicket" :filterByTicket="filterByTicket"></search-fiche-ticket>
        <!-- bouton d"export -->
        <v-btn v-if="rightsUser.detailed_view && filterByTicket" @click="exportFiches" small color="success" title="Export CSV">
          <v-icon left small>mdi-file-export</v-icon>
          exporter
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn-toggle v-if="!showOnlyNewsFichesActifs" borderless class="elevation-2">
          <v-btn v-if="!filterAdvancedActivate && !isFilterVselect" small @click="showOnlyNewsFichesFunction">
            <v-icon small>mdi-new-box</v-icon>
            <v-chip color="red" x-small dark>
              {{ $t('messagerie.fiche.new_fiche') }}
            </v-chip>
          </v-btn>
        </v-btn-toggle>

        <!-- mohamed -->
        <v-btn-toggle v-if="showOnlyNewsFichesActifs" borderless class="elevation-2">
          <v-btn small @click="resetFilters">
            <v-chip color="red" x-small dark class="mr-1">
              {{ $t('actions.cancel') }}
            </v-chip>

            <v-chip color="red" x-small dark>{{ numberNewFiches }}</v-chip>
          </v-btn>
        </v-btn-toggle>
        <!-- full screen -->
        <div v-if="
          rightsUser.detailed_view &&
          !filterAdvancedActivate &&
          !isFilterVselect
        ">
          <v-btn @click="dialog = true" small>
            <v-icon left small dark>mdi-filter</v-icon>
            <v-chip class="filtre-module" x-small dark>
              {{ $t('messagerie.chat.module') }}
            </v-chip>
          </v-btn>

          <v-dialog v-model="dialog" scrollable width="300px">
            <v-card>
              <v-card-title>{{
                $t('messagerie.filtres.select_module')
              }}</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 60vh;">
                <v-radio-group v-model="byModule" column @change="showOnlyFichesByModule(byModule)">
                  <v-radio v-for="module in modules" :key="module.id" :label="module.name === 'GTA' ? 'GTA & TAG Compteur' : module.name
                    " :value="'module.name.' + module.name"></v-radio>
                </v-radio-group>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" block @click="dialog = false">
                  {{ $t('actions.close') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <v-btn-toggle v-if="
          rightsUser.detailed_view &&
          !filterAdvancedActivate &&
          !isFilterVselect
        " class="elevation-2">
          <v-menu :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small>
                <v-icon left small dark>mdi-filter</v-icon>
                <v-chip color="blue" x-small dark>{{
                  $t('messagerie.filtres.title')
                }}</v-chip>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-anomalie': isFilterActive(
                    'type_demande.anomalie'
                  ),
                }" style="color: black; border: 2px solid #ff5252;" small color="#fff"
                  @click="showOnlyFichesByType('type_demande.anomalie')">
                  {{ $t('messagerie.parametrages.type_demande.anomalie') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-utilisation': isFilterActive(
                    'type_demande.utilisation'
                  ),
                }" style="color: black; border: 2px solid #3f51b5;" small color="#fff"
                  @click="showOnlyFichesByType('type_demande.utilisation')">
                  {{ $t('messagerie.parametrages.type_demande.utilisation') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-developpement': isFilterActive(
                    'type_demande.developpement'
                  ),
                }" style="color: black; border: 2px solid #00bcd4;" small color="#fff"
                  @click="showOnlyFichesByType('type_demande.developpement')">
                  {{ $t('messagerie.parametrages.type_demande.developpement') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-renseignements': isFilterActive(
                    'type_demande.renseignements'
                  ),
                }" style="color: black; border: 2px solid #4caf50;" small color="#fff"
                  @click="showOnlyFichesByType('type_demande.renseignements')">
                  {{
                    $t('messagerie.parametrages.type_demande.renseignements')
                  }}
                </v-chip>
              </v-list-item>

              <v-list-item>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-haute': isFilterActive(
                    'ordre_priorite.haute'
                  ),
                }" style="color: black; border: 2px solid #ff5252;" small color="#fff"
                  @click="showOnlyFichesByPriority('ordre_priorite.haute')">
                  {{ $t('messagerie.parametrages.ordre_priorite.haute') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-moyenne': isFilterActive(
                    'ordre_priorite.moyenne'
                  ),
                }" style="color: black; border: 2px solid #3f51b5;" small color="#fff"
                  @click="showOnlyFichesByPriority('ordre_priorite.moyenne')">
                  {{ $t('messagerie.parametrages.ordre_priorite.moyenne') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-basse': isFilterActive(
                    'ordre_priorite.basse'
                  ),
                }" style="color: black; border: 2px solid #00bcd4;" small color="#fff"
                  @click="showOnlyFichesByPriority('ordre_priorite.basse')">
                  {{ $t('messagerie.parametrages.ordre_priorite.basse') }}
                </v-chip>
              </v-list-item>

              <v-list-item>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-bloquant': isFilterActive('impact.bloquant'),
                }" style="color: black; border: 2px solid #ff5252;" small color="#fff"
                  @click="showOnlyFichesByImpact('impact.bloquant')">
                  {{ $t('messagerie.parametrages.impact.bloquant') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-cosmetique': isFilterActive(
                    'impact.cosmetique'
                  ),
                }" style="color: black; border: 2px solid #009688;" small color="#fff"
                  @click="showOnlyFichesByImpact('impact.cosmetique')">
                  {{ $t('messagerie.parametrages.impact.cosmetique') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-mineur': isFilterActive('impact.mineur'),
                }" style="color: black; border: 2px solid #009688;" small color="#fff"
                  @click="showOnlyFichesByImpact('impact.mineur')">
                  {{ $t('messagerie.parametrages.impact.mineur') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-majeur': isFilterActive('impact.majeur'),
                }" style="color: black; border: 2px solid #009688;" small color="#fff"
                  @click="showOnlyFichesByImpact('impact.majeur')">
                  {{ $t('messagerie.parametrages.impact.majeur') }}
                </v-chip>
              </v-list-item>

              <v-list-item>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-demande_envoyee': isFilterActive(
                    'etat.demande_envoyee'
                  ),
                }" style="color: black; border: 2px solid #ff5252;" small color="#fff"
                  @click="showOnlyFichesByState('etat.demande_envoyee')">
                  {{ $t('messagerie.parametrages.etat.demande_envoyee') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-prise_en_compte': isFilterActive(
                    'etat.prise_en_compte'
                  ),
                }" style="color: black; border: 2px solid #fb8c00;" small color="#fff"
                  @click="showOnlyFichesByState('etat.prise_en_compte')">
                  {{ $t('messagerie.parametrages.etat.prise_en_compte') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-en_attente': isFilterActive(
                    'etat.en_attente'
                  ),
                }" style="color: black; border: 2px solid #fb8c00;" small color="#fff"
                  @click="showOnlyFichesByState('etat.en_attente')">
                  {{ $t('messagerie.parametrages.etat.en_attente') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-demande_terminee': isFilterActive(
                    'etat.demande_terminee'
                  ),
                }" style="color: black; border: 2px solid #4caf50;" small color="#fff"
                  @click="showOnlyFichesByState('etat.demande_terminee')">
                  {{ $t('messagerie.parametrages.etat.demande_terminee') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-demande_cloturee': isFilterActive(
                    'etat.demande_cloturee'
                  ),
                }" style="color: black; border: 2px solid #4caf50;" small color="#fff"
                  @click="showOnlyFichesByState('etat.demande_cloturee')">
                  {{ $t('messagerie.parametrages.etat.demande_cloturee') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-prise_en_compte_client': isFilterActive(
                    'etat.prise_en_compte_client'
                  ),
                }" style="color: black; border: 2px solid #555555;" small color="#fff"
                  @click="showOnlyFichesByState('etat.prise_en_compte_client')">
                  {{
                    $t('messagerie.parametrages.etat.prise_en_compte_client')
                  }}
                </v-chip>
              </v-list-item>

              <v-list-item v-if="rightsUser.hotline">
                <v-chip class="v-list-gap" :class="{ 'filter-active-suivi': isFilterActive('suivi') }"
                  style="color: black; border: 2px solid #F0F;" small color="#fff"
                  @click="showOnlyFichesByTracked('suivi')">
                  Suivi
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-doublon': isFilterActive('doublon'),
                }" style="color: black; border: 2px solid #9E9E9E;" small color="#fff"
                  @click="showOnlyFichesByDoublon('doublon')">
                  Doublon
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-appel_client': isFilterActive('appel_client'),
                }" style="color: black; border: 2px solid #0F0;" small color="#fff"
                  @click="showOnlyFichesByAppelClient('appel_client')">
                  Appel client
                </v-chip>
                <!-- rappel client -->
                <v-chip class="v-list-gap" :class="{
                  'filter-active-rappel_client': isFilterActive('rappel_client'),
                }" style="color: black; border: 2px solid #fb8c00;" small color="#fff"
                  @click="showOnlyFichesByRappelClient('rappel_client')">
                  Rappel client
                </v-chip>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn v-if="filtresByModuleActifs" @click="resetFilters" small>
            <v-chip color="red" x-small dark>
              {{ $t('actions.cancel') }}
            </v-chip>
          </v-btn>
        </v-btn-toggle>

        <!-- short screen -->
      </div>

      <message-list-view-dense-detailled v-if="detailledView" @select-fiche="selectFiche"
        @scroll-end="filterSimpleEndScroll" :loading="loading" :fiches="fiches" :filterType="filterType" :bases="bases"
        :arrayfilterActivate="arrayfilterActivate" :optionsScrollChat="optionsScrollChat" :selectedFiche="selectedFiche"
        :endSimple="endSimple" :skipSimple="skipSimple" :loadingScrollSimple="loadingScrollSimple" :user="user"
        :filterAdvancedActivate="filterAdvancedActivate"></message-list-view-dense-detailled>
    </div>

    <div class="card-one fill-height" v-if="!detailledView">
      <messagerie-header :filterType="filterType" :viewExport="viewExport" :tokenRights="token_rights" :bases="bases"
        :filter_simple.sync="filter_simple" :filters_simple="filters_simple"
        :filterAdvancedActivate="filterAdvancedActivate" :arrayFilterChecked="arrayFilterChecked"
        :filterUser="filterUser" :detailledView="detailledView" :rightsUser="rightsUser" :fichesObj="fiches"
        :usersObj="users" :filtre_users="filtre_users" :isFilterVselect="isFilterVselect" :addTagsFiche="addTagsFiche"
        :tagsPlaneteOnline="tagsPlaneteOnline" :main_hotline_base="main_hotline_base" />

      <div class="d-flex flex-row align-center mb-3 filter-container">
        <message-list-switch-view></message-list-switch-view>

        <search-fiche-ticket :searchTicket="searchTicket" :filterByTicket="filterByTicket"></search-fiche-ticket>

        <!-- bouton d"export -->
        <v-btn v-if="rightsUser.detailed_view && filterByTicket" @click="exportFiches" small color="success" title="Export CSV">
          <v-icon small>mdi-file-export</v-icon>
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn-toggle v-if="!showOnlyNewsFichesActifs && !isFilterVselect" borderless class="elevation-2">
          <v-btn v-if="!filterAdvancedActivate" small @click="showOnlyNewsFichesFunction">
            <v-icon :color="'red'">mdi-new-box</v-icon>
            <!-- <v-chip color="red" x-small dark>{{ numberNewFiches }}</v-chip> -->
          </v-btn>
        </v-btn-toggle>

        <!-- mohamed -->
        <v-btn-toggle v-if="showOnlyNewsFichesActifs" borderless class="elevation-2">
          <v-btn v-if="numberNewFiches > 0" small @click="resetFilters">
            <v-chip color="red" x-small dark class="mr-1">
              <v-icon small dark>mdi-close</v-icon>
            </v-chip>

            <v-chip color="red" x-small dark>{{ numberNewFiches }}</v-chip>
          </v-btn>
        </v-btn-toggle>
        <!-- mini -->
        <div v-if="!filterAdvancedActivate && !isFilterVselect">
          <v-btn @click="dialog = true" small>
            <v-chip class="filtre-module" x-small dark>
              <v-icon small dark>mdi-filter</v-icon>
            </v-chip>
          </v-btn>

          <v-dialog v-model="dialog" scrollable width="300px">
            <v-card>
              <v-card-title>{{
                $t('messagerie.filtres.select_module')
              }}</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 60vh;">
                <v-radio-group v-model="byModule" column @change="showOnlyFichesByModule(byModule)">
                  <v-radio v-for="module in modules" :key="module.id" :label="module.name === 'GTA' &&
                      rightsUser.hotline &&
                      rightsUser.detailed_view
                      ? 'GTA & TAG Compteur'
                      : module.name
                    " :value="'module.name.' + module.name"></v-radio>
                </v-radio-group>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" block @click="dialog = false">
                  {{ $t('actions.close') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <v-btn-toggle v-if="!filterAdvancedActivate && !isFilterVselect" class="elevation-2">
          <v-menu :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small>
                <v-chip color="blue" x-small dark>
                  <v-icon small dark>mdi-filter</v-icon>
                </v-chip>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-anomalie': isFilterActive(
                    'type_demande.anomalie'
                  ),
                }" style="color: black; border: 2px solid #ff5252;" small color="#fff"
                  @click="showOnlyFichesByType('type_demande.anomalie')">
                  {{ $t('messagerie.parametrages.type_demande.anomalie') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-utilisation': isFilterActive(
                    'type_demande.utilisation'
                  ),
                }" style="color: black; border: 2px solid #3f51b5;" small color="#fff"
                  @click="showOnlyFichesByType('type_demande.utilisation')">
                  {{ $t('messagerie.parametrages.type_demande.utilisation') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-developpement': isFilterActive(
                    'type_demande.developpement'
                  ),
                }" style="color: black; border: 2px solid #00bcd4;" small color="#fff"
                  @click="showOnlyFichesByType('type_demande.developpement')">
                  {{ $t('messagerie.parametrages.type_demande.developpement') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-renseignements': isFilterActive(
                    'type_demande.renseignements'
                  ),
                }" style="color: black; border: 2px solid #4caf50;" small color="#fff"
                  @click="showOnlyFichesByType('type_demande.renseignements')">
                  {{
                    $t('messagerie.parametrages.type_demande.renseignements')
                  }}
                </v-chip>
              </v-list-item>

              <v-list-item>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-haute': isFilterActive(
                    'ordre_priorite.haute'
                  ),
                }" style="color: black; border: 2px solid #ff5252;" small color="#fff"
                  @click="showOnlyFichesByPriority('ordre_priorite.haute')">
                  {{ $t('messagerie.parametrages.ordre_priorite.haute') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-moyenne': isFilterActive(
                    'ordre_priorite.moyenne'
                  ),
                }" style="color: black; border: 2px solid #3f51b5;" small color="#fff"
                  @click="showOnlyFichesByPriority('ordre_priorite.moyenne')">
                  {{ $t('messagerie.parametrages.ordre_priorite.moyenne') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-basse': isFilterActive(
                    'ordre_priorite.basse'
                  ),
                }" style="color: black; border: 2px solid #00bcd4;" small color="#fff"
                  @click="showOnlyFichesByPriority('ordre_priorite.basse')">
                  {{ $t('messagerie.parametrages.ordre_priorite.basse') }}
                </v-chip>
              </v-list-item>

              <v-list-item>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-bloquant': isFilterActive('impact.bloquant'),
                }" style="color: black; border: 2px solid #ff5252;" small color="#fff"
                  @click="showOnlyFichesByImpact('impact.bloquant')">
                  {{ $t('messagerie.parametrages.impact.bloquant') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-cosmetique': isFilterActive(
                    'impact.cosmetique'
                  ),
                }" style="color: black; border: 2px solid #009688;" small color="#fff"
                  @click="showOnlyFichesByImpact('impact.cosmetique')">
                  {{ $t('messagerie.parametrages.impact.cosmetique') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-mineur': isFilterActive('impact.mineur'),
                }" style="color: black; border: 2px solid #009688;" small color="#fff"
                  @click="showOnlyFichesByImpact('impact.mineur')">
                  {{ $t('messagerie.parametrages.impact.mineur') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-majeur': isFilterActive('impact.majeur'),
                }" style="color: black; border: 2px solid #009688;" small color="#fff"
                  @click="showOnlyFichesByImpact('impact.majeur')">
                  {{ $t('messagerie.parametrages.impact.majeur') }}
                </v-chip>
              </v-list-item>

              <v-list-item>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-demande_envoyee': isFilterActive(
                    'etat.demande_envoyee'
                  ),
                }" style="color: black; border: 2px solid #ff5252;" small color="#fff"
                  @click="showOnlyFichesByState('etat.demande_envoyee')">
                  {{ $t('messagerie.parametrages.etat.demande_envoyee') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-prise_en_compte': isFilterActive(
                    'etat.prise_en_compte'
                  ),
                }" style="color: black; border: 2px solid #fb8c00;" small color="#fff"
                  @click="showOnlyFichesByState('etat.prise_en_compte')">
                  {{ $t('messagerie.parametrages.etat.prise_en_compte') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-en_attente': isFilterActive(
                    'etat.en_attente'
                  ),
                }" style="color: black; border: 2px solid #fb8c00;" small color="#fff"
                  @click="showOnlyFichesByState('etat.en_attente')">
                  {{ $t('messagerie.parametrages.etat.en_attente') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-demande_terminee': isFilterActive(
                    'etat.demande_terminee'
                  ),
                }" style="color: black; border: 2px solid #4caf50;" small color="#fff"
                  @click="showOnlyFichesByState('etat.demande_terminee')">
                  {{ $t('messagerie.parametrages.etat.demande_terminee') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-demande_cloturee': isFilterActive(
                    'etat.demande_cloturee'
                  ),
                }" style="color: black; border: 2px solid #4caf50;" small color="#fff"
                  @click="showOnlyFichesByState('etat.demande_cloturee')">
                  {{ $t('messagerie.parametrages.etat.demande_cloturee') }}
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-prise_en_compte_client': isFilterActive(
                    'etat.prise_en_compte_client'
                  ),
                }" style="color: black; border: 2px solid #555555;" small color="#fff"
                  @click="showOnlyFichesByState('etat.prise_en_compte_client')">
                  {{
                    $t('messagerie.parametrages.etat.prise_en_compte_client')
                  }}
                </v-chip>
              </v-list-item>
              <v-list-item v-if="rightsUser.hotline">
                <v-chip class="v-list-gap" :class="{ 'filter-active-suivi': isFilterActive('suivi') }"
                  style="color: black; border: 2px solid #F0F;" small color="#fff"
                  @click="showOnlyFichesByTracked('suivi')">
                  Suivi
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-doublon': isFilterActive('doublon'),
                }" style="color: black; border: 2px solid #9E9E9E;" small color="#fff"
                  @click="showOnlyFichesByDoublon('doublon')">
                  Doublon
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-appel_client': isFilterActive('appel_client'),
                }" style="color: black; border: 2px solid #0F0;" small color="#fff"
                  @click="showOnlyFichesByAppelClient('appel_client')">
                  Appel client
                </v-chip>
                <v-chip class="v-list-gap" :class="{
                  'filter-active-rappel_client': isFilterActive('rappel_client'),
                }" style="color: black; border: 2px solid #fb8c00;" small color="#fff"
                  @click="showOnlyFichesByRappelClient('rappel_client')">
                  Rappel client
                </v-chip>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn v-if="filtresByModuleActifs" @click="resetFilters" small>
            <v-chip color="red" x-small dark>
              <v-icon dense dark>mdi-close</v-icon>
            </v-chip>
          </v-btn>
        </v-btn-toggle>
        <!-- mohamed test -->

        <v-btn v-if="preferences.hotlineListView == 0" small @click="expandList">
          <v-icon small>mdi-arrow-expand-vertical</v-icon>
        </v-btn>
      </div>

      <message-list-view-complete v-if="preferences.hotlineListView == 0" @select-fiche="selectFiche"
        @scroll-end="filterSimpleEndScroll" :loading.sync="loading" :fiches="fiches" :filterType="filterType"
        :bases="bases" :arrayfilterActivate="arrayfilterActivate" :optionsScrollChat="optionsScrollChat"
        :selectedFiche="selectedFiche" :endSimple="endSimple" :skipSimple="skipSimple"
        :loadingScrollSimple="loadingScrollSimple" :user="user"
        :filterAdvancedActivate="filterAdvancedActivate"></message-list-view-complete>

      <message-list-view-dense v-if="preferences.hotlineListView == 1" @select-fiche="selectFiche"
        @scroll-end="filterSimpleEndScroll" :loading="loading" :fiches="fiches" :filterType="filterType" :bases="bases"
        :arrayfilterActivate="arrayfilterActivate" :optionsScrollChat="optionsScrollChat" :selectedFiche="selectedFiche"
        :endSimple="endSimple" :skipSimple="skipSimple" :loadingScrollSimple="loadingScrollSimple" :user="user"
        :filterAdvancedActivate="filterAdvancedActivate" :socket="socket"></message-list-view-dense>
    </div>

    <chat v-if="selectedFiche != null && !createFicheForm && !detailledView" @close-chat="selectedFiche = null"
      @change-content="(text) => (content = text)" :files="files" :optionsScrollChat="optionsScrollChat"
      :keyLangCheat="keyLangCheat" :selectedFiche.sync="selectedFiche" :modules="modules"
      :main_hotline_base="main_hotline_base" :user="user" :rightsUser="rightsUser" :users="users" :impacts="impacts"
      :ordre_priorites="ordre_priorites" :etats="etats" :type_demandes="type_demandes"
      :tagsPlaneteOnline="tagsPlaneteOnline" :messagesInWritings="messagesInWritings" :userId="userId" :socket="socket"
      :informations="informations" :detailledView="detailledView">
    </chat>
    <v-card class="messagerie-chat messagerie-chat--no-select rounded pa-4 card-three overflow-y-hidden" v-if="
      selectedFiche == null &&
      !viewSettings &&
      !createFicheForm &&
      !detailledView
    ">
      <p>{{ $t('messagerie.text.select_fiche') }}</p>
    </v-card>
    <!-- Formulaire de création d'une fiche -->
    <form-fiche v-if="createFicheForm && !detailledView" @send="sendFiche" :impacts="impacts" :modules="allModules"
      :subModules="subModules" :type_demandes="type_demandes" :ordre_priorites="ordre_priorites"
      :bases_clientsArraySelectable="bases_clientsArraySelectable" :dialogPieceJointe="dialogPieceJointe"
      :keyLangCheat="keyLangCheat" :detailledView="detailledView" />

    <dialog-attachment v-model="files" @addFile="addFileFiche" @remove-file-fiche="removeFileFiche"
      :show.sync="dialogPieceJointe"></dialog-attachment>

    <v-dialog v-model="formFilter" persistent scrollable max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            filterUpdate == null
              ? $t('messagerie.filtres.form.title_add')
              : $t('messagerie.filtres.form.title_update')
          }}</span>
        </v-card-title>
        <v-card-text>
          <vue-scroll class="pr-4">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-bind:label="$t('messagerie.filtres.form.title_filter')" v-model="filterInputTitle"
                    @keyup="checkNotSameNameFilter()"></v-text-field>
                  <v-alert v-if="sameNameFilter" type="error">
                    {{ $t('messagerie.filtres.form.same_name') }}
                  </v-alert>
                </v-col>
                <v-col cols="6">
                  <v-checkbox v-bind:label="$t('messagerie.filtres.form.add_archived')"
                    v-model="filterAddArchived"></v-checkbox>
                  <v-alert v-if="sameNameFilter" type="error">
                    {{ $t('messagerie.filtres.form.same_name') }}
                  </v-alert>
                </v-col>
                <v-divider></v-divider>
                <v-col cols="6">
                  <h2 class="mb-3">
                    {{ $t('messagerie.filtres.form.list_type') }}
                  </h2>
                  <v-card flat min-height="300">
                    <draggable :list="filterTypeArray" class="cursor-grab" @start="startDragAddFilter()"
                      @end="endDragAddFilter()" forceFallback group="filter">
                      <v-list-item v-for="filter in filterTypeArray" :key="filter.id"
                        class="filter-draggable-list-item primary rounded mb-1" dense dark>
                        <v-list-item-action>
                          <v-icon small>mdi-filter</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="ml-n6">
                          <v-list-item-title>{{ $t(filter.name) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-fade-transition>
                        <v-overlay opacity=".3" v-show="draggingRemove" absolute>
                          {{ $t('messagerie.filtres.drag_to_remove') }}
                          <!--                          Glisser ici pour retirer-->
                        </v-overlay>
                      </v-fade-transition>
                    </draggable>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <h2 class="mb-3">
                    {{ $t('messagerie.filtres.form.selection') }}
                  </h2>

                  <v-card flat min-height="300">
                    <draggable class="filter-drag-list" @start="startDragRemoveFilter()" @end="endDragRemoveFilter()"
                      group="filter" :list="filterTypeArrayDrop">
                      <v-list-item v-for="(filter, index) in filterTypeArrayDrop" :key="filter.id"
                        class="filter-draggable-list-item success rounded mb-1" dense dark>
                        <v-list-item-content>
                          <v-list-item-title>{{ index + 1 }} -
                            {{ $t(filter.name) }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon small>mdi-check</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-fade-transition>
                        <v-overlay opacity=".3" v-show="draggingAdd" absolute>
                          {{ $t('messagerie.filtres.drag_to_add') }}
                        </v-overlay>
                      </v-fade-transition>
                    </draggable>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card class="filter-form mb-5" v-for="(typeFilter, index) in filterTypeArrayDrop"
                    :key="typeFilter.id" elevation="4">
                    <h2>{{ index + 1 }} - {{ $t(typeFilter.name) }}</h2>
                    <v-divider class="mb-5"></v-divider>
                    <template v-if="[1, 9].includes(typeFilter.id)">
                      <v-text-field :label="$t(typeFilter.name)" v-model="typeFilter.value"></v-text-field>
                    </template>

                    <template v-if="typeFilter.id == 8">
                      <v-date-picker v-model="typeFilter.value"></v-date-picker>
                    </template>
                    <v-autocomplete v-if="![1, 3, 8].includes(typeFilter.id)" :items="typeFilter.items"
                      :label="$t(typeFilter.name)" multiple chips persistent-hint v-model="typeFilter.selected"
                      item-value="id" return-object>
                      <template v-slot:item="{ item }">
                        {{ $t(item.text) }}
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="selectAllFilter(typeFilter)">
                          <v-list-item-action>
                            <v-icon :color="typeFilter.items.length > 0
                                ? 'indigo darken-4'
                                : ''
                              ">
                              {{
                                typeFilter.items.length ==
                                  typeFilter.selected.length
                                  ? 'mdi-close-box'
                                  : typeFilter.selected.length == 0
                                    ? 'mdi-checkbox-blank-outline'
                                    : 'mdi-minus-box'
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('messagerie.filtres.form.select_all') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                    <v-autocomplete v-if="[3].includes(typeFilter.id)" :items="typeFilter.items"
                      :label="$t(typeFilter.name)" multiple chips persistent-hint v-model="typeFilter.selected"
                      item-value="id" return-object>
                      <template v-slot:item="{ item }">
                        {{ item.text }}
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="selectAllFilter(typeFilter)">
                          <v-list-item-action>
                            <v-icon :color="typeFilter.items.length > 0
                                ? 'indigo darken-4'
                                : ''
                              ">
                              {{
                                typeFilter.items.length ==
                                  typeFilter.selected.length
                                  ? 'mdi-close-box'
                                  : typeFilter.selected.length == 0
                                    ? 'mdi-checkbox-blank-outline'
                                    : 'mdi-minus-box'
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('messagerie.filtres.form.select_all') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </vue-scroll>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-col cols="12" v-if="errorFilterForm.length > 0">
              <v-alert v-for="error in errorFilterForm" :key="error.input" type="error">
                <span v-if="!error.inputTrad">
                  {{
                    $t(error.input) +
                    ' ' +
                    $t('messagerie.form_error.new_fiche.' + error.error)
                  }}
                </span>
                <span v-if="error.inputTrad">
                  {{
                    $t(error.input) +
                    ' ' +
                    $t('messagerie.form_error.new_fiche.' + error.error)
                  }}
                </span>
              </v-alert>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" class="float-right">
              <v-btn color="blue" dark @click="cancelDialogFilter()" :disabled="loaderFilterSend">
                <v-icon>mdi-close-box</v-icon>
              </v-btn>
              <v-btn color="green" :dark="!sameNameFilter" @click="saveFilter()" :disabled="sameNameFilter"
                :loading="loaderFilterSend" class="ml-3">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </v-col>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Suppression d'un filtre -->
    <v-dialog v-model="dialogRemoveFilter" max-width="480">
      <v-card v-if="filterDeleting">
        <v-card-title>{{ $t('messagerie.filtres.delete_dialog.confirm_delete') }}
        </v-card-title>
        <v-card-text>
          <p>
            <v-icon color="warning" class="mr-3">mdi-alert</v-icon>
            {{ $t('messagerie.filtres.delete_dialog.confirm_delete_texte') }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="
            filterDeleting = null;
          dialogRemoveFilter = false;
          ">{{ $t('actions.cancel') }}
          </v-btn>
          <v-btn color="error" @click="removeFilter(filterDeleting)">{{ $t('actions.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Arrêt de la saisie d'une fiche -->
    <v-dialog v-model="dialogStopNewFiche" max-width="480">
      <v-card>
        <v-card-title>{{ $t('messagerie.text.writing.title') }}</v-card-title>
        <v-card-text>
          <p>
            <v-icon color="warning" class="mr-3">mdi-alert</v-icon>
            {{ $t('messagerie.text.writing.subtitle') }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="
            filterDeleting = null;
          dialogStopNewFiche = false;
          ">{{ $t('actions.cancel') }}
          </v-btn>
          <v-btn color="error" @click="
            selectFicheAfterCheck(
              selectedFicheBeforeStopNewFiche,
              'new_fiche_reset'
            )
            ">{{ $t('actions.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Affiche image en grand -->
    <v-dialog v-model="dialogLightBox" width="unset" overlay-opacity="0.8" @click:outside="
      imgLightbox = null;
    dialogLightBox = false;
    ">
      <div class="d-flex flex-column align-stretch justify-start" @mouseenter="imgLightBoxOver = true"
        @mouseleave="imgLightBoxOver = false">
        <!--        <v-progress-circular v-if="imgLightBoxLoading" indeterminate color="white"></v-progress-circular>-->
        <v-toolbar color="transparent" class="imgLightBoxToolbar" :class="{ hover: imgLightBoxOver }" elevation="0">
          <v-spacer></v-spacer>
          <v-btn icon @click="
            imgLightbox = null;
          dialogLightBox = false;
          ">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-toolbar>
        <img class="dialog-img elevation-0 mt-n16" :src="imgLightbox" @load="imgLightBoxLoading = false" />
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import chat from '@/Components/Views/Hotline/Chat/Chat';
import HotlineBus from '@/Components/Views/Hotline/HotlineBus';
import MessagerieService from '@/Services/MessagerieService';
import PlaneteOnlineService from '@/Services/PlaneteOnlineService';
import UserService from '@/Services/UserService';
import Parsing from '@/Utils/Parsing';
import moment from 'moment';
import openSocket from 'socket.io-client';
import draggable from 'vuedraggable';
import Vuex from 'vuex';

// import the component and the necessary extensions
let parametrages_messagerie = require('../../../Config/Hotline.json');

export default {
  name: 'Hotline',
  components: {
    TextLoader: () => import('@/Components/Commons/UiElements/TextLoader'),
    MessagerieHeader: () =>
      import('@/Components/Views/Hotline/List/HotlineHeader'),
    FormFiche: () => import('@/Components/Views/Hotline/Ticket/CreateForm'),
    FormSettings: () => import('@/Components/Views/Hotline/HotlineSettings'),
    MessageListSwitchView: () =>
      import('@/Components/Views/Hotline/List/TicketListSwitchView'),
    MessageListViewComplete: () =>
      import('@/Components/Views/Hotline/List/TicketListViewComplete'),
    MessageListViewDense: () =>
      import('@/Components/Views/Hotline/List/TicketListViewDense'),
    MessageListViewDenseDetailled: () =>
      import('@/Components/Views/Hotline/List/TicketListViewDenseDetailled'),
    ChipAttachment: () =>
      import('@/Components/Commons/Inputs/Attachment/ChipAttachment'),
    DialogAttachment: () =>
      import('@/Components/Commons/Inputs/Attachment/DialogAttachment'),
    MenuFiche: () => import('@/Components/Views/Hotline/Ticket/TicketMenu'),
    Chat: () => import('@/Components/Views/Hotline/Chat/Chat'),
    MessageCardMiniDetails: () =>
      import('@/Components/Views/Hotline/Ticket/TicketCardMiniDetails'),
    SearchFicheTicket: () =>
      import('@/Components/Views/Hotline/List/SearchTicket'),
    draggable,
  },
  filters: {
    truncate(text, length, clamp) {
      clamp = clamp || '...';
      let node = document.createElement('div');
      node.innerHTML = text;
      let content = node.textContent;
      return content.length > length
        ? content.slice(0, length) + clamp
        : content;
    },
  },
  data() {
    return {
      token_rights: null,
      detailledView: null,
      showOnlyNewsFiches: false,
      showOnlyNewsFichesStock: [],
      searchTicket: '',
      filterByTicket: false,
      HotlineBus,
      draggingAdd: false,
      draggingRemove: false,
      expandedList: false,
      keyLangCheat: null,
      formFilter: false,
      filterType: false,
      viewExport: false,
      viewSettings: false,
      filterAdvancedActivate: false,
      filterTypeArray: [
        {
          id: 1,
          name: 'messagerie.filtres.form.libelle',
          value: '',
        },
        {
          id: 2,
          name: 'messagerie.filtres.form.url',
          items: [],
          selected: [],
        },
        {
          id: 3,
          name: 'messagerie.filtres.form.module',
          items: [],
          selected: [],
        },
        {
          id: 4,
          name: 'messagerie.parametrages.ordre_priorite.title',
          items: [],
          selected: [],
        },
        {
          id: 5,
          name: 'messagerie.parametrages.type_demande.title',
          items: [],
          selected: [],
        },
        {
          id: 6,
          name: 'messagerie.parametrages.etat.title',
          items: [],
          selected: [],
        },
        {
          id: 7,
          name: 'messagerie.parametrages.impact.title',
          items: [],
          selected: [],
        },
        {
          id: 8,
          name: 'messagerie.filtres.form.date_ouverture',
          value: '',
        },
        {
          id: 9,
          name: 'messagerie.filtres.form.details',
          value: '',
        },
      ],
      filterInputTitle: '',
      filterAddArchived: false,
      sameNameFilter: false,
      errorFilterForm: [],
      loaderFilterSend: false,
      filterTypeArrayDrop: [],
      filterTypeArrayChecked: [],
      filterUpdate: null,
      arrayFilterChecked: [],
      arrayfilterActivate: [],
      filterUser: [],
      filter_simple: null,
      filters_simple: [],
      createFicheForm: false,
      nativeExtensions: [new Image()],
      content: ``,
      user: null,
      loading: false,
      searchNeedle: '',
      userId: null,

      panelsOpen: [],
      optionsScrollChat: {
        bar: {
          keepShow: true,
        },
        scrollPanel: {
          easing: 'easeInQuad',
          speed: 800,
        },
        vuescroll: {
          wheelScrollDuration: 300,
        },
        rail: {
          gutterOfEnds: '1px',
          gutterOfSide: '1px',
        },
      },

      selectedFiche: null,
      errorsNewFiches: [],
      newFiche: {
        id: null,
        users: [],
        userCreate: null,
        objet: '',
        phone: '',
        module: null,
        sous_module: null,
        type_demande: null,
        url: null,
        impact: null,
        ordre_priorite: null,
        messages: [],
        content: '',
      },
      contentNewFiche: '',
      messageInterne: {
        selectUsers: [],
        users: [],
        content: ``,
      },
      addUsersFiche: {
        selectUsers: [],
        users: [],
      },
      addTagsFiche: {
        selectTags: [],
        tags: [],
      },
      removeUsersFiche: [],
      removeTagsFiche: [],
      dialogMsgInterne: false,
      dialogGestionUsers: false,
      dialogRemoveFilter: false,
      dialogStopNewFiche: false,
      dialogStopTapFiche: false,
      stopNewFicheDetailled: false,
      imgLightBoxOver: false,
      dialogLightBox: false,
      imgLightbox: null,
      imgLightBoxLoading: false,
      selectedFicheBeforeStopNewFiche: null,
      dialogValidateMsg: false,
      dialogPieceJointe: false,
      dialogSelectFicheDetaillees: false,
      formDataValidateMessage: null,
      filterDeleting: null,
      fiches: [],
      bases: [],
      bases_clients: [],
      bases_clientsArraySelectable: [],
      impacts: [],
      type_demandes: [],
      ordre_priorites: [],
      etats: [],
      users: [],
      loadingScrollSimple: false,
      skipSimple: 0,
      endSimple: false,
      rightsUser: {},
      tagsUser: [],
      tagsPlaneteOnline: [],
      host: null,
      hostGlobale: null,
      main_hotline_base: false,
      socket: null,
      messagesInWritings: [],
      messageInUpdate: {
        msg: {},
        inUpdate: false,
      },
      timeoutMessaging: null,
      files: [],
      filtresActifs: false,
      filtresByModuleActifs: false,
      objFiches: {},
      byType: [],
      byPriority: [],
      byImpact: [],
      byState: [],
      byModule: [],
      bySuivi: [],
      byDoublon: [],
      byAppelClient: [],
      byRappelClient: [],
      dialog: null,
      seeFilter: false,
      showOnlyNewsFichesActifs: false,
      isBySuivi: false,
      filtre_users: [],
      isFilterVselect: false,
      chatComponent: null,
    };
  },
  computed: {
    asRightDetails() {
      return (
        this.isMasterBase() &&
        (this.rightsUser.hotline || this.rightsUser.filter_detail)
      );
    },
    showSelectedDetailled() {
      return this.selectedFiche != null && this.detailledView;
    },
    /**
     * Ajoute le module "divers" aux modules
     */
    allModules() {
      let modules = this.modules.slice();
      modules.push({
        id: -1,
        name: this.$t('app.modules.other'),
        subModules: [],
      });
      return modules;
    },
    ...Vuex.mapState(['informations', 'preferences', 'modules', 'messagerie']),
    /**
     * Récupère le nombre de nouvelles fiches
     */
    numberNewFiches() {
      return this.fiches.filter((f) => f.fiche.new_fiche).length;
    },
    /**
     * Retourne la langue choisie
     */
    currentLang() {
      return this.preferences.lang;
    },
    /**
     * retourne les sous module du module
     */
    subModules() {
      if (this.modules) {
        return this.newFiche.module
          ? this.modules.find((mod) => mod.id == this.newFiche.module.id)
            .subModules
          : [];
      } else {
        return [];
      }
    },
    isFilterActive() {
      return (filterType) =>
        this.byType.includes(filterType) ||
        this.byPriority.includes(filterType) ||
        this.byImpact.includes(filterType) ||
        this.byState.includes(filterType) ||
        this.byModule.includes(filterType) ||
        this.bySuivi.includes(filterType) ||
        this.byDoublon.includes(filterType) ||
        this.byAppelClient.includes(filterType) ||
        this.byRappelClient.includes(filterType);
    },
  },
  methods: {
    // Fonction pour charger un brouillon dans le composant chat.vue
    async loadDraftInChat(ficheId) {
      // Ici, nous avons besoin de trouver le composant chat à partir de sa référence
      const chatComponent = this.$refs.chatComponent; // "chatComponent" est l'attribut "ref" que nous définirons dans le template
      if (chatComponent) {
        await chatComponent.loadDraft(ficheId);
      }
    },
    changeMessagesNonLus(messagesNonLus) {
      document.title =
        '(' + messagesNonLus + ')' + ' Messagerie | planete-online';
      this.messagesNonLus = messagesNonLus;
    },
    /**
     * Permet de savoir combien de message l'user n'a pas vu
     * @var fiche la fiche
     */
    userNotSeen(fiche) {
      if (typeof fiche.fiche.usersSeen == 'undefined') {
        return fiche.fiche.number_messages;
      }
      if (fiche.fiche.usersSeen.length == 0) {
        return fiche.fiche.number_messages;
      } else {
        let userSeen = fiche.fiche.usersSeen.find(
          (u) => u.idUser == this.user.id
        );
        if (typeof userSeen != 'undefined') {
          return userSeen.nb;
        } else {
          return fiche.fiche.number_messages || 0;
        }
      }
    },
    async changeView(state) {
      try {
        this.selectedFiche = null;
        let view = '';
        if (state) {
          view = 'details';
        } else {
          view = 'simple';
        }
        this.$store.commit('setDefaultView', view);
        let store = this.$_.pick(this.$store.state, ['preferences']);
        await PlaneteOnlineService.postInterface(store);
        this.detailledView = state;
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * Check si une fiche est en cours d'écriture
     */
    newFicheInWriting() {
      return (
        this.newFiche.objet != '' ||
        this.newFiche.impact != null ||
        this.newFiche.etat != null ||
        this.newFiche.type_demande != null ||
        this.newFiche.phone != '' ||
        this.newFiche.module != null ||
        (this.newFiche.content != '' && this.newFiche.content != '<p></p>')
      );
    },
    chatInWriting() {
      return this.content != '' && this.content != '<p></p>';
    },
    addFileFiche(fileAdded) {
      if (this.selectedFiche != null) {
        if (typeof this.selectedFiche.fiche.files == 'undefined') {
          this.selectedFiche.fiche.files = [];
        }
        this.selectedFiche.fiche.files.push(fileAdded);
      }
    },
    removeFileFiche(fileRemoved) {
      if (this.selectedFiche != null) {
        this.selectedFiche.fiche.files = this.selectedFiche.fiche.files.filter(
          (f) => f.new_name != fileRemoved.new_name
        );
      }
    },
    archivedFiche(fiche) {
      this.fiches = this.fiches.filter((f) => f.fiche.id != fiche.fiche.id);
      if (this.selectedFiche != null) {
        if (fiche.fiche.id == this.selectedFiche.fiche.id) {
          this.selectedFiche = null;
        }
      }
    },
    // ------------------ Mohamed ------------------ //

    // Permet de faire un filtre par type
    async showOnlyFichesByType(byType) {
      // Vérifiez si le type actuel est déjà sélectionné
      const isSelected = this.byType.includes(byType);

      // Si le type est déjà sélectionné, le supprimer de la liste des filtres
      if (isSelected) {
        this.byType = this.byType.filter((type) => type !== byType);
      } else {
        // Sinon, ajoutez-le à la liste des filtres
        this.byType.push(byType);
      }

      // Vérifiez s'il y a des types sélectionnés
      if (
        this.byType.length === 0 &&
        this.byPriority.length === 0 &&
        this.byImpact.length === 0 &&
        this.byState.length === 0 &&
        this.bySuivi.length === 0 &&
        this.byRappelClient.length === 0 &&
        this.byDoublon.length === 0 &&
        this.byAppelClient.length === 0 &&
        this.searchTicket == ''
      ) {
        // Si aucun type n'est sélectionné, réinitialisez les filtres

        this.resetFilters();
      } else {
        // Sinon, mettez à jour les filtres et récupérez les fiches

        this.getMoreFiches();
        this.filtresActifs = true;
      }
    },

    // Permet de faire un filtre par priorité
    async showOnlyFichesByPriority(priority) {
      const isSelected = this.byPriority.includes(priority);

      if (isSelected) {
        this.byPriority = this.byPriority.filter((p) => p !== priority);
      } else {
        this.byPriority.push(priority);
      }

      if (
        this.byPriority.length === 0 &&
        this.byType.length === 0 &&
        this.byImpact.length === 0 &&
        this.byState.length === 0 &&
        this.bySuivi.length === 0 &&
        this.byRappelClient.length === 0 &&
        this.byDoublon.length === 0 &&
        this.byAppelClient.length === 0 &&
        this.searchTicket == ''
      ) {
        this.resetFilters();
      } else {
        this.getMoreFiches();
      }
    },
    // Permet de faire un filtre par impact
    async showOnlyFichesByImpact(impact) {
      const isSelected = this.byImpact.includes(impact);

      if (isSelected) {
        this.byImpact = this.byImpact.filter((i) => i !== impact);
      } else {
        this.byImpact.push(impact);
      }

      if (
        this.byImpact.length === 0 &&
        this.byType.length === 0 &&
        this.byPriority.length === 0 &&
        this.byState.length === 0 &&
        this.bySuivi.length === 0 &&
        this.byRappelClient.length === 0 &&
        this.byDoublon.length === 0 &&
        this.byAppelClient.length === 0 &&
        this.searchTicket == ''
      ) {
        this.resetFilters();
      } else {
        this.getMoreFiches();
      }
    },
    // Permet de faire un filtre par état
    async showOnlyFichesByState(state) {
      const isSelected = this.byState.includes(state);

      if (isSelected) {
        this.byState = this.byState.filter((s) => s !== state);
        this.filtresActifs = false;
      } else {
        this.byState.push(state);
        this.filtresActifs = true;
      }

      if (
        this.byState.length === 0 &&
        this.byType.length === 0 &&
        this.byPriority.length === 0 &&
        this.byImpact.length === 0 &&
        this.bySuivi.length === 0 &&
        this.byRappelClient.length === 0 &&
        this.byDoublon.length === 0 &&
        this.byAppelClient.length === 0 &&
        this.searchTicket == ''
      ) {
        this.resetFilters();
      } else {
        this.getMoreFiches();
        this.filtresActifs = true;
      }
    },
    async showOnlyFichesByTracked(suivi) {

      const isSelected = this.bySuivi.includes(suivi);

      if (isSelected) {
        this.bySuivi = this.bySuivi.filter((s) => s !== suivi);
        this.filtresActifs = false;
      } else {
        this.bySuivi.push(suivi);
        this.filtresActifs = true;
      }

      if (
        this.bySuivi.length === 0 &&
        this.byType.length === 0 &&
        this.byPriority.length === 0 &&
        this.byImpact.length === 0 &&
        this.byState.length === 0 &&
        this.byRappelClient.length === 0 &&
        this.byAppelClient.length === 0 &&
        this.byDoublon.length === 0 &&
        this.searchTicket == ''
      ) {
        this.resetFilters();
      } else {
        this.getMoreFiches();
        this.filtresActifs = true;
      }
    },
    // par doublon 
    async showOnlyFichesByDoublon(doublon) {

      const isSelected = this.byDoublon.includes(doublon);

      if (isSelected) {
        this.byDoublon = this.byDoublon.filter((s) => s !== doublon);
        this.filtresActifs = false;
      } else {
        this.byDoublon.push(doublon);
        this.filtresActifs = true;
      }

      if (
        this.byDoublon.length === 0 &&
        this.byType.length === 0 &&
        this.byPriority.length === 0 &&
        this.byImpact.length === 0 &&
        this.byState.length === 0 &&
        this.byRappelClient.length === 0 &&
        this.bySuivi.length === 0 &&
        this.byAppelClient.length === 0 &&
        this.searchTicket == ''
      ) {
        this.resetFilters();
      } else {
        this.getMoreFiches();
        this.filtresActifs = true;
      }
    },
    // par appel client
    async showOnlyFichesByAppelClient(appelClient) {

      const isSelected = this.byAppelClient.includes(appelClient);

      if (isSelected) {
        this.byAppelClient = this.byAppelClient.filter((s) => s !== appelClient);
        this.filtresActifs = false;
      } else {
        this.byAppelClient.push(appelClient);
        this.filtresActifs = true;
      }

      if (
        this.byAppelClient.length === 0 &&
        this.byType.length === 0 &&
        this.byPriority.length === 0 &&
        this.byImpact.length === 0 &&
        this.byState.length === 0 &&
        this.byRappelClient.length === 0 &&
        this.bySuivi.length === 0 &&
        this.byDoublon.length === 0 &&
        this.searchTicket == ''
      ) {
        this.resetFilters();
      } else {
        this.getMoreFiches();
        this.filtresActifs = true;
      }
    },
    //  par rappel client
    async showOnlyFichesByRappelClient(rappelClient) {

      const isSelected = this.byRappelClient.includes(rappelClient);

      if (isSelected) {
        this.byRappelClient = this.byRappelClient.filter((s) => s !== rappelClient);
        this.filtresActifs = false;
      } else {
        this.byRappelClient.push(rappelClient);
        this.filtresActifs = true;
      }

      if (
        this.byRappelClient.length === 0 &&
        this.byType.length === 0 &&
        this.byPriority.length === 0 &&
        this.byImpact.length === 0 &&
        this.byState.length === 0 &&
        this.bySuivi.length === 0 &&
        this.byDoublon.length === 0 &&
        this.byAppelClient.length === 0
      ) {
        this.resetFilters();
      } else {
        this.getMoreFiches();
        this.filtresActifs = true;
      }
    },

    // Permet de faire un filtre par module
    async showOnlyFichesByModule(module) {
      this.byModule = module;
      this.filtresByModuleActifs = true;
      this.getMoreFiches();
    },

    // Permet de faire d'annuler le filtre
    resetFilters(clear, enter = false) { 
      if (enter == 'enter') {
          if (this.searchTicket == '') {
            return null;
          }
        }
      const filtersToReset = {
        byType: [],
        byPriority: [],
        byImpact: [],
        byState: [],
        byModule: [],
        bySuivi: [],
        byDoublon: [],
        byAppelClient: [],
        byRappelClient: [],
        filtresActifs: false,
        filtresByModuleActifs: false,
        skipSimple: 0,
        endSimple: false,
        searchTicket: '',
        filter_simple: {
          id: 5,
          cleTraduction: 'all',
        },
        showOnlyNewsFichesActifs: false,
        isFilterVselect: false,
      };

      Object.keys(filtersToReset).forEach((filter) => {
        this[filter] = filtersToReset[filter];
      });
      let objFiches = {
        skip: this.skipSimple,
        rights: this.rightsUser,
        filter_simple: this.filter_simple,
      };

      if (!this.rightsUser.hotline) {
        objFiches.tags = this.tagsUser.map((u) => u._id);
        objFiches.tagsname = this.tagsUser.map((u) => u.tag.name);
        if (this.hostGlobale == null) {
          objFiches.bases = [this.host];
        } else {
          objFiches.bases = this.hostGlobale.bases.map((b) => b.name);
          objFiches.bases.push(this.host);
        }
      }
      this.loading = true;
      this.getFichesFunction(objFiches).then(() => { });
    },

    async getMoreFiches() {
      this.skipSimple = 0;
      // Ajout des filtres sous forme de string en séparant par une virgule
      let valeurFiltre = '';

      const addFilterValues = (filterArray) => {
        if (!filterArray) return;
        filterArray.forEach((item) => {
          valeurFiltre += item + ',';
        });
      };

      addFilterValues(this.byType);
      addFilterValues(this.byPriority);
      addFilterValues(this.byImpact);
      addFilterValues(this.byState);

      // Ajout des filtres uniques (non tableaux)
      const addUniqueFilters = (filter) => {
        if (filter && !Array.isArray(filter)) {
          valeurFiltre += filter + ',';
        }
      };

      addUniqueFilters(this.byModule);
      addFilterValues(this.bySuivi);
      addFilterValues(this.byDoublon);
      addFilterValues(this.byAppelClient);
      addFilterValues(this.byRappelClient);

      // Enlever la dernière virgule si elle existe
      if (valeurFiltre.endsWith(',')) {
        valeurFiltre = valeurFiltre.slice(0, -1);
      }

      // Ajout de searchTicket si ce n'est pas une chaîne vide
      if (this.searchTicket !== '') {
        valeurFiltre += ',' + `searchTicket.${this.searchTicket}`;
      }

      // Si aucun filtre n'est sélectionné, on affiche toutes les fiches
      if (valeurFiltre == '') {
        this.resetFilters();
        return;
      }

      this.filter_simple = {
        id: 6,
        valeur: valeurFiltre,
      };

      // Construction de l'objet objFiches
      let objFiches = {
        skip: this.skipSimple,
        rights: this.rightsUser,
        filter_simple: this.filter_simple,
        bases: this.bases,
      };

      // Ajout des tags si l'utilisateur n'est pas hotline
      if (!this.rightsUser.hotline) {
        objFiches.tags = this.tagsUser.map((u) => u._id);
        objFiches.tagsname = this.tagsUser.map((u) => u.tag.name);
        if (this.hostGlobale == null) {
          objFiches.bases = [this.host];
        } else {
          objFiches.bases = this.hostGlobale.bases.map((b) => b.name);
          objFiches.bases.push(this.host);
        }
      }

      // Appel de la fonction pour récupérer les fiches
      this.getFichesFunction(objFiches).then(() => { });
    },

    async showOnlyNewsFichesFunction() {
      // verifier si on est client ou callcenter
      if (!this.isMasterBase()) {
        this.showOnlyNewsFiches = !this.showOnlyNewsFiches;
        if (this.showOnlyNewsFiches) {
          this.showOnlyNewsFichesStock = this.fiches;
          this.fiches = this.fiches.filter((f) => f.fiche.new_fiche);
        } else {
          this.fiches = this.showOnlyNewsFichesStock;
        }
      } else {
        this.showOnlyNewsFichesActifs = true;

        this.getMoreFiches();
      }
    },

    async selectTicketFiche(idTicket = 0) {
      if (idTicket != 0) {
        await MessagerieService.getFichesById({
          id: idTicket,
        })
          .then((res) => {
            if (typeof res.error == 'undefined') {
              this.fiches.push(res);
              this.selectFiche(res);
            } else {
              if (res.error == '') {
                this.fiches = [];
              }
            }
          })
          .catch((err) => {
            this.$nError('Erreur lors de la recherche de fiche');
          })
          .finally(() => {
            this.loading = false;
            this.filterByTicket = true;
          });
      }
    },
    /**
     * Permet de récupérer une fiche via son ticket
     * @var ticket le ticket de la fiche
     */
    async searchFicheTicket(clear, enter = false) {
      if (clear == null) {
        if (enter == 'enter') {
          if (this.searchTicket == '') {
            return null;
          }
        }
        // let bases = []
        let rights = {};
        if (!this.rightsUser.hotline) {
          rights.tags = this.tagsUser.map((u) => u._id);
          if (this.hostGlobale == null) {
            this.bases = [this.host];
          } else {
            this.bases = this.hostGlobale.bases.map((b) => b.name);
            this.bases.push(this.host);
          }
        }

        this.skipSimple = 0;
        this.endSimple = true;
        this.loading = true;
        this.filterByTicket = true;
        // Récupération des fiches en BDD
        let trad_search_key_statut = [];
        let trad_search_key_etat = [];
        parametrages_messagerie.type_demandes.forEach((o) => {
          o.Traduction = this.$t(
            'messagerie.parametrages.' + o.cleTraduction
          ).toLowerCase();
          trad_search_key_statut.push(o);
        });
        parametrages_messagerie.etats.forEach((o) => {
          o.Traduction = this.$t(
            'messagerie.parametrages.' + o.cleTraduction
          ).toLowerCase();
          trad_search_key_etat.push(o);
        });

        let searchTicket_statut_id = trad_search_key_statut.find(
          (f) =>
            this.searchTicket
              .toLowerCase()
              .indexOf(f.Traduction.toLowerCase()) != -1
        );
        if (searchTicket_statut_id === undefined) {
          searchTicket_statut_id = '';
        } else {
          this.searchTicket = this.searchTicket
            .toLowerCase()
            .replace(searchTicket_statut_id.Traduction.toLowerCase(), '');
          searchTicket_statut_id = searchTicket_statut_id.id;
        }
        let searchTicket_etat_id = trad_search_key_etat.find(
          (f) =>
            this.searchTicket
              .toLowerCase()
              .indexOf(f.Traduction.toLowerCase()) != -1
        );
        if (searchTicket_etat_id === undefined) {
          searchTicket_etat_id = '';
        } else {
          this.searchTicket = this.searchTicket
            .toLowerCase()
            .replace(searchTicket_etat_id.Traduction.toLowerCase(), '');
          searchTicket_etat_id = searchTicket_etat_id.id;
        }

        let filterCriteria = {};
        // verifier si un filtre isFilterActive est actif pour l'ajouter à la requête
        if (this.isFilterActive) {
          filterCriteria.byType = this.byType;
          filterCriteria.byPriority = this.byPriority;
          filterCriteria.byImpact = this.byImpact;
          filterCriteria.byState = this.byState;
          filterCriteria.byModule = this.byModule;
          filterCriteria.bySuivi = this.bySuivi;
          filterCriteria.byDoublon = this.byDoublon;
          filterCriteria.byAppelClient = this.byAppelClient;
        }
        // si l'une des valeur retourne un tableu vide on la supprime
        for (const key in filterCriteria) {
          if (filterCriteria[key].length == 0) {
            delete filterCriteria[key];
          }
        }

        await MessagerieService.getFichesByTicket({
          token_rights: this.token_rights,
          search: this.searchTicket.trim(),
          search_id_etat: searchTicket_etat_id,
          search_id_status: searchTicket_statut_id,
          rightsUser: this.rightsUser,
          bases: this.bases,
          rights,
          filters: filterCriteria || {},
        })
          .then((res) => {
            if (typeof res.error == 'undefined') {
              // afficher de la plus récente à la plus ancienne
              // res.reverse();
              this.fiches = res;
              // this.selectFiche(this.fiches[0]);
            } else {
              if (res.error == '') {
                this.fiches = [];
              }
            }
          })
          .catch((err) => {
            this.$nError('Erreur lors de la recherche de fiche');
          })
          .finally(() => {
            this.loading = false;
            this.filterByTicket = true;
          });
      } else {
        this.skipSimple = 0;
        this.endSimple = false;
        //Sinon on refresh tout
        this.searchTicket = '';
        let objFiches = {
          skip: this.skipSimple,
          rights: this.rightsUser,
          filter_simple: this.filter_simple,
        };
        if (!this.rightsUser.hotline) {
          objFiches.tags = this.tagsUser.map((u) => u._id);
          objFiches.tagsname = this.tagsUser.map((u) => u.tag.name);
          if (this.hostGlobale == null) {
            objFiches.bases = [this.host];
          } else {
            objFiches.bases = this.hostGlobale.bases.map((b) => b.name);
            objFiches.bases.push(this.host);
          }
        }
        // verifier si un filtre isFilterActive est actif pour l'ajouter à la requête
        // if (this.isFilterActive) {
        //   objFiches.byType = this.byType;
        //   objFiches.byPriority = this.byPriority;
        //   objFiches.byImpact = this.byImpact;
        //   objFiches.byState = this.byState;
        //   objFiches.byModule = this.byModule;
        //   objFiches.bySuivi = this.bySuivi;
        //   objFiches.byDoublon = this.byDoublon;
        //   objFiches.byAppelClient = this.byAppelClient;
        // }



        this.loading = true;
        this.getFichesFunction(objFiches).then(() => { });
        this.filterByTicket = false;
      }
    },
    // export des fiches en csv de la recherche
    async exportFiches() {
      // export des fiches en csv de la recherche
      let fileName = 'Export fiches';
      let CSV = '';
      let row = 'Ticket; Client; Demandeur; Etat; Type de demande; Date demande; Module; Details (Module); Commentaire; Base; Date prévisionnelle; Gestion utilisateur; Suivi; Tag(s); Date etat terminée; Appel client; Détail d\'appel; Ticket doublon;';
      CSV += row + '\r\n';
      
      this.fiches.forEach((fiche) => {
        CSV += (fiche.fiche.ticket || '').replace(/[\r\n;]+/g, ' ') + ';';
        CSV += (fiche.fiche.client || '').replace(/[\r\n;]+/g, ' ') + ';';
        CSV += ((fiche.fiche.userCreate?.lastname || '') + ' ' + (fiche.fiche.userCreate?.firstname || '')).replace(/[\r\n;]+/g, ' ') + ';';
        CSV += this.$t('messagerie.parametrages.' + (fiche.fiche.etat?.text || '')).replace(/[\r\n;]+/g, ' ') + ';';
        CSV += this.$t('messagerie.parametrages.' + (fiche.fiche.type_demande?.text || '')).replace(/[\r\n;]+/g, ' ') + ';';
        CSV += (fiche.createdAt?.substr(0, 10) || '').replace(/[\r\n;]+/g, ' ') + ';';
        
        let module = fiche.fiche.module?.name || '';
        if (fiche.fiche.sous_module) {
          module += ' | ' + (fiche.fiche.sous_module.name || '').replace(/[\r\n;]+/g, ' ');
        }
        CSV += module.replace(/[\r\n;]+/g, ' ') + ';';
        
        CSV += (fiche.fiche.label_module || '').replace(/[\r\n;]+/g, ' ') + ';';
        CSV += (fiche.fiche.comment || '').replace(/[\r\n;]+/g, ' ') + ';';
        CSV += (fiche.fiche.url?.text || '').replace(/[\r\n;]+/g, ' ') + ';';
        CSV += (fiche.fiche.date_previsionnelle || '').replace(/[\r\n;]+/g, ' ') + ';';
        
        let users = fiche.fiche.users && fiche.fiche.users.length > 0
          ? fiche.fiche.users.map(user => `${user.lastname} ${user.firstname}`).join(', ')
          : 'Aucun';
        CSV += users.replace(/[\r\n;]+/g, ' ') + ';';
        
        CSV += fiche.fiche.suivi ? 'Oui;' : 'Non;';
        
        let tags = fiche.fiche.tags
          ? fiche.fiche.tags.filter(tag => tag.tag && tag.tag.name).map(tag => tag.tag.name).join(', ')
          : '';
        CSV += tags.replace(/[\r\n;]+/g, ' ') + ';';
        
        CSV += fiche.fiche.etat === 'etat.demande_terminee' ? (fiche.fiche.date_changement_etat || '') + ';' : ';';
        
        let dernierAppel = fiche.fiche.appel_client && Array.isArray(fiche.fiche.appel_client) && fiche.fiche.appel_client.length > 0
          ? fiche.fiche.appel_client[fiche.fiche.appel_client.length - 1].details
          : 'Non';
        CSV += dernierAppel.replace(/[\r\n;]+/g, ' ') + ';';
        
        let infosAppels = fiche.fiche.appel_client && Array.isArray(fiche.fiche.appel_client)
          ? fiche.fiche.appel_client.map(appel => `${appel.user} - ${appel.date} - ${appel.details}`).join(', ')
          : '';
        CSV += infosAppels.replace(/[\r\n;]+/g, ' ') + ';';
        
        CSV += (fiche.fiche.doublon ? 'Oui' : 'Non') + ';';
        CSV += '\r\n';
      });
      let uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
      let link = document.createElement('a');
      link.href = uri;

      //set the visibility hidden so it will not effect on your web-layout
      link.style = 'visibility:hidden';
      link.download = fileName + '.csv';

      //this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    /**
     * Fonction de récupération des fiches
     */
    async getFichesFunction(data, type) {
      data.token_rights = this.token_rights;
      if (type != 'scroll_simple') {
        this.loading = true;
      }
      // Récupération des fiches en BDD
      MessagerieService.getFiches(data)
        .then((res) => {
          // console.log("get fiche length", res.length, this.endSimple)
          if (res.length == 0) this.endSimple = true;
          if (type == 'scroll_simple') {
            this.fiches = this.fiches.concat(res);
          } else {
            this.fiches = res;
          }
          this.fiches = this.fiches.sort(this.compareFicheTime);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingScrollSimple = false;
          this.loading = false;
        });
    },
    /**
     * Event lors du changement du filtre simple
     */
    async changeFilterSimple() {
      this.isFilterVselect = true;
      this.loading = true;
      this.$store.commit('setPreselectedFilterSimple', this.filter_simple);

      let store = this.$_.pick(this.$store.state, ['preferences']);

      PlaneteOnlineService.postInterface(store).then(() => {
        let objFiches = {
          skip: this.skipSimple,
          rights: this.rightsUser,
          filter_simple: this.filter_simple,
        };
        //if (!this.rightsUser.hotline) {
        objFiches.tags = this.tagsUser.map((u) => u._id);
        objFiches.tagsname = this.tagsUser.map((u) => u.tag.name);
        if (this.hostGlobale == null) {
          objFiches.bases = [this.host];
        } else {
          objFiches.bases = this.hostGlobale.bases.map((b) => b.name);
          objFiches.bases.push(this.host);
        }
        this.getFichesFunction(objFiches).then(() => { });
        if (this.filter_simple.id == 5) {
          this.isFilterVselect = false;
        }
      });
    },
    // event lors du changement du filtre-users
    async changeFilterUsers() {
      this.isFilterVselect = true;
      this.loading = true;
      this.filter_simple = {
        id: 8,
        valeur: this.filter_users.lastname + ' ' + this.filter_users.firstname,
        idUser: this.filter_users.id,
      };
      let objFiches = {
        skip: this.skipSimple,
        rights: this.rightsUser,
        filter_simple: this.filter_simple,
        bases: this.bases,
      };
      // if (!this.rightsUser.hotline) {
      //   objFiches.tags = this.tagsUser.map((u) => u._id);
      //   if (this.hostGlobale == null) {
      //     objFiches.bases = [this.host];
      //   } else {
      //     objFiches.bases = this.hostGlobale.bases.map((b) => b.name);
      //     objFiches.bases.push(this.host);
      //   }
      // }
      this.getFichesFunction(objFiches).then(() => { });
    },
    changeFilterTags() {
      this.isFilterVselect = true;
      this.loading = true;
      this.filter_simple = {
        id: 9,
        valeur: this.filter_tags.tag,
      };
      let objFiches = {
        skip: this.skipSimple,
        rights: this.rightsUser,
        filter_simple: this.filter_simple,
        bases: this.bases,
      };
      // if (!this.rightsUser.hotline) {
      //   objFiches.tags = this.tagsUser.map((u) => u._id);
      //   if (this.hostGlobale == null) {
      //     objFiches.bases = [this.host];
      //   } else {
      //     objFiches.bases = this.hostGlobale.bases.map((b) => b.name);
      //     objFiches.bases.push(this.host);
      //   }
      // }
      this.getFichesFunction(objFiches).then(() => { });
    },
    startDragAddFilter() {
      this.draggingAdd = true;
      // document.body.classList.toggle("cursor-grabbing", true)
    },
    endDragAddFilter() {
      this.draggingAdd = false;
      // document.body.classList.toggle("cursor-grabbing", false)
    },
    startDragRemoveFilter() {
      this.draggingRemove = true;
      // document.body.classList.toggle("cursor-grabbing", true)
    },
    endDragRemoveFilter() {
      this.draggingRemove = false;
      // document.body.classList.toggle("cursor-grabbing", false)
    },
    expandList() {
      this.expandedList = !this.expandedList;
      HotlineBus.$emit('toggle-expand-message', this.expandedList);
    },

    /**
     * Fonction lancée au drag leave de la zone de drop
     */
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('lighten-3');
      event.currentTarget.classList.remove('lighten-1');
    },
    getUrlImage(user) {
      return (
        'http://localhost:8182/upload/photoUser/' +
        'microservice.planett.net/25'
      );
    },
    /**
     * Connecte le socket
     */
    connectSocketIO() {
      let url = '';
      if (location.host.includes('localhost')) {
        url = 'http://127.0.0.1:8182';
      } else {
        url = `${location.protocol}//${location.host}`;
      }

      try {
        let params = {
          transports: ['websocket', 'polling'],
          path: location.host.includes('localhost')
            ? ''
            : '/planete-online-sockets/socket.io',
          query: {
            base: this.host,
          },
        };
        if (this.host) {
          params.extraHeaders = {
            'base-dev': this.host,
          };
        }

        this.socket = openSocket(url, params);
        this.subscribeSocketIO();
      } catch (err) {
        this.setError('noServer');
      }
    },
    /**
     * Mise en place des sockets
     */
    subscribeSocketIO() {
      // socket pour les date prévisionnelle
      this.socket.on('DATE_PREVISIONNELLE', (data) => {
        let fiche = this.fiches.find((f) => f.fiche.id == data.fiche);
        if (fiche) {
          fiche.fiche.date_previsionnelle = data.date_previsionnelle;
          this.$set(fiche.fiche, 'date_previsionnelle', data.date_previsionnelle);
        }
      });
      // socket pour les appels clients
      this.socket.on('APPEL_CLIENT', (data) => {
        let fiche = this.fiches.find((f) => f.fiche.id == data.fiche);
        if (fiche) {
          fiche.fiche.appel_client = data.appel_client;
          this.$set(fiche.fiche, 'appel_client', data.appel_client);
        }
      });
      //  socket supprimer un historique de la liste d appel client 
      this.socket.on('REMOVE_APPEL_CLIENT', (data) => {
        let fiche = this.fiches.find((f) => f.fiche.id == data.fiche);
        if (fiche) {
          fiche.fiche.appel_client = data.appel_client;
          this.$set(fiche.fiche, 'appel_client', data.appel_client);
        }
      });
      // socket pour des suivis d'une fiche
      this.socket.on('SUIVI_FICHE', (data) => {
        let fiche = this.fiches.find((f) => f.fiche.id == data.fiche);
        if (fiche) {
          fiche.fiche.suivi = data.isTracked;
          this.$set(fiche.fiche, 'suivi', data.isTracked);
        }
      });
      // socket pour le retrait de suivi d'une fiche
      this.socket.on('REMOVE_SUIVI_FICHE', (data) => {
        let fiche = this.fiches.find((f) => f.fiche.id == data.fiche);
        if (fiche) {
          fiche.fiche.suivi = false;
          this.$set(fiche.fiche, 'suivi', false);
        }
      });
      // socket pour suppression de pièce jointe
      // mettre a jour la liste des fiches et les pièces jointes
      this.socket.on('REMOVE_FILE_MESSAGE', (data) => {
        let fiche = this.fiches.find((f) => f.fiche.id == data.fiche);
        if (fiche) {
          // Trouver le message correspondant dans la fiche
          let message = fiche.fiche.messages.find((m) => m.id == data.message.id);
          if (message) {
            // Retirer les fichiers supprimés du message
            message.files = data.file
          }
          // Mettre à jour la liste des fiches 
          this.$set(fiche.fiche, 'messages', fiche.fiche.messages);
        }
      });
      /**
       * Socket ajout ou suppression d'un user d'une fiche
       */
      this.socket.on('add_remove_users_fiche', (data) => {
        //Si c'est un ajout
        let checkUser = data.users.filter(
          (u) => u.id == this.userId && u.url == this.getDns()
        );
        if (data.type === 'add') {
          if (checkUser) {
            let checkFiche = this.fiches.find(
              (f) => f.fiche.id == data.fiche.fiche.id
            );
            if (!checkFiche) {
              this.fiches.unshift(data.fiche);
            }
          }
        }
        //En cas de suppression
        if (data.type === 'delete') {
          //On vérifie que l'user connecté est concerné
          if (checkUser) {
            //S'il l'est on vérifie qu'il a la fiche dans ses fiches
            let checkFiche = this.fiches.find(
              (f) => f.fiche.id == data.fiche.fiche.id
            );
            if (checkFiche) {
              //S'il a la liste on vérifie qu'il a pas un tag qui l'autorise quand meme a voir
              let tagsId = this.tagsUser.map((t) => t._id);

              let presenceTags = data.fiche.tags.find((t) =>
                tagsId.includes(t._id)
              );
              //Si aucun tag alors on remove la fiche
              if (!presenceTags) {
                let index = this.fiches.findIndex(
                  (f) => f.fiche.id == data.fiche.fiche.id
                );
                this.fiches.slice(index, 1);
              }
            }
          }
        }
      });
      /**
       * Socket ajout ou suppression d'un tag d'une fiche
       */
      this.socket.on('add_remove_tags_fiche', (data) => {
        let tagsId = this.tagsUser.map((t) => t._id);

        //Si c'est un ajout
        if (data.type === 'add') {
          let checkTags = data.tags.filter((t) => tagsId.includes(t._id));
          if (checkTags) {
            let checkFiche = this.fiches.find(
              (f) => f.fiche.id == data.fiche.fiche.id
            );
            if (!checkFiche) {
              this.fiches.unshift(data.fiche);
            }
          }
        }
        //En cas de suppression
        if (data.type === 'delete') {
          //On vérifie que l'user connecté est concerné
          let checkTags = data.tags.filter((t) => tagsId.includes(t._id));

          if (checkTags) {
            //S'il l'est on vérifie qu'il a la fiche dans ses fiches
            let checkFiche = this.fiches.find(
              (f) => f.fiche.id == data.fiche.fiche.id
            );
            if (checkFiche) {
              //S'il a la liste on vérifie qu'il a pas un tag qui l'autorise quand meme a voir
              let tagsId = this.tagsUser.map((t) => t._id);

              let presenceTags = data.fiche.tags.find((t) =>
                tagsId.includes(t._id)
              );
              //Si aucun tag alors on remove la fiche
              if (!presenceTags) {
                let index = this.fiches.findIndex(
                  (f) => f.fiche.id == data.fiche.fiche.id
                );
                this.fiches.slice(index, 1);
              }
            }
          }
        }
      });
      /**
       * Socket lors de l'ajout d'une fiche
       */
      this.socket.on('newFiche', (data) => {
        let tagsId = this.tagsUser.map((t) => t._id);

        let presenceTags = data.fiche.tags.find((t) => tagsId.includes(t._id));
        if (
          presenceTags ||
          (data.fiche.userCreate.id == this.userId &&
            data.fiche.userCreate.url == this.getDns())
        ) {
          let autorisation = true;

          //Si on est sur les filtres simples
          if (this.arrayfilterActivate.length > 0) {
            let tmpAutorisation = [];
            let autorisation_filter = [];
            //Pour chaque filtres principal
            this.arrayfilterActivate.forEach((main_filter, i) => {
              tmpAutorisation.push(false);

              // et pour chacun de leurs filtres, si la valeur est dans le filtre on mets la variable à true
              main_filter.forEach((filter) => {
                if (filter.id == 2) {
                  //Si dans les url selectionnées il y a celle de la fiche alors autorisation
                  if (
                    filter.selected
                      .map((b) => b.text)
                      .includes(data.fiche.url.value.name)
                  ) {
                    tmpAutorisation[i] = true;
                  }
                }
                if (filter.id == 3) {
                  //Si dans les modules selectionné il y a celui de la fiche alors autorisation
                  if (
                    filter.selected
                      .map((m) => m.id)
                      .includes(data.fiche.module.id)
                  ) {
                    tmpAutorisation[i] = true;
                  }
                }
                if (filter.id == 4) {
                  //Si dans les ordre de priorité selectionné il y a celui de la fiche alors autorisation
                  if (
                    filter.selected
                      .map((o) => o.id)
                      .includes(data.fiche.ordre_priorite.value.id)
                  ) {
                    tmpAutorisation[i] = true;
                  }
                }
                if (filter.id == 5) {
                  //Si dans les type de demandes selectionné il y a celui de la fiche alors autorisation
                  if (
                    filter.selected
                      .map((t) => t.id)
                      .includes(data.fiche.type_demande.value.id)
                  ) {
                    tmpAutorisation[i] = true;
                  }
                }
                if (filter.id == 6) {
                  //Si dans les etats selectionné il y a celui de la fiche alors autorisation
                  if (
                    filter.selected
                      .map((e) => e.id)
                      .includes(data.fiche.etat.value.id)
                  ) {
                    tmpAutorisation[i] = true;
                  }
                }
                if (filter.id == 7) {
                  //Si dans les impacts selectionné il y a celui de la fiche alors autorisation
                  if (
                    filter.selected
                      .map((i) => i.id)
                      .includes(data.fiche.impact.value.id)
                  ) {
                    tmpAutorisation[i] = true;
                  }
                }
              });

              // Si TOUS les filtres matchent la nouvelle fiche, passe autorisation à true
              autorisation = tmpAutorisation.every((v) => v);
            });
          }
          //Quand on est sur les archives on ajoute pas la fiche
          if (this.filter_simple.id != 3) {
            if (autorisation) {
              this.fiches.unshift(data);
              if (
                this.main_hotline_base &&
                parseInt(data.fiche.userCreate.id) != this.userId
              ) {
                this.$notification.show(
                  'Messagerie Planete Online',
                  {
                    icon: require('@/Assets/Images/Logo/logo-planete-online.png'),
                    body: 'Nouvelle fiche liaison reçue',
                    silent: true,
                  },
                  {}
                );
                this.$uiSounds.play('messagerieNewCard');
              }
            }
          }
        }
      });
      /**
       * Socket d'un message mis à jour
       */
      this.socket.on('MESSAGE_UPDATED', (data) => {
        this.fiches.forEach((fiche) => {
          if (fiche.fiche.messages != undefined) {
            let msg = fiche.fiche.messages.find((m) => m.id == data.message.id);
            if (msg) {
              if (typeof fiche.fiche.last_message_interne !== 'undefined') {
                if (fiche.fiche.last_message_interne.id == msg.id) {
                  fiche.fiche.last_message_interne = msg;
                }
              }
              if (fiche.fiche.last_message.id == msg.id) {
                fiche.fiche.last_message = msg;
              }
              msg.content = data.message.content;
              msg.updated = true;
              this.$forceUpdate();
            }
          }
        });
      });
      /**
       * Ecoute pour savoir si un message est en cours d'écriture
       */
      this.socket.on('message-in-writing', (data) => {
        if (data.type == 1) {
          let user = this.messagesInWritings.find(
            (m) => m.user.id == data.user.id
          );
          if (!user) {
            this.messagesInWritings.push({
              user: data.user,
              fiche: data.fiche,
            });
          }

          const vs = this.$refs['scrollChat'];
          if (vs !== undefined && vs.getScrollProcess()['v'] == 1) {
            const panel = vs.scrollPanelElm;
            vs.scrollTo({
              x: 100 * panel.scrollWidth,
              y: 100 * panel.scrollHeight,
            });
          }
        } else {
          let user_index = this.messagesInWritings.findIndex(
            (m) => m.user.id == data.user.id
          );
          this.messagesInWritings.splice(user_index, 1);
        }
      });
      /**
       * Socket changement de params
       */
      this.socket.on('change_params', (data) => {
        let fiche = this.fiches.find((f) => f.fiche.id == data.fiche);
        if (data.type == 'etat') {
          fiche.fiche.etat = data.object;
        }
        if (data.type == 'priorite') {
          fiche.fiche.ordre_priorite = data.object;
        }
        if (data.type == 'type_demande') {
          fiche.fiche.type_demande = data.object;
        }
        if (data.type == 'impact') {
          fiche.fiche.impact = data.object;
        }
      });
      /**
       * Réception d'un message
       */
      this.socket.on('MESSAGE', async (data) => {
        this.skipSimple += 1;
        let fiche = this.fiches.find((f) => f.fiche.id == data.fiche);
        // si la fiche n'est pas dans la liste on la récupère depuis l'api et on la push dans la liste
        if (!fiche) {
          await MessagerieService.getFichesById({
            newMsgId: data.fiche,
          }).then((res) => {
            // this.fiches.push(res);
            this.fiches.unshift(res);
            fiche = this.fiches.find((f) => f.fiche.id == data.fiche);
          });
        }

        fiche.fiche.usersSeen = data.userSeen;
        fiche.fiche.new_fiche = data.new_fiche;
        fiche.fiche.files = data.files;
        if (typeof fiche.fiche.messages !== 'undefined') {
          fiche.fiche.messages.push(data.message);
        }
        if (data.message.type == 1) {
          fiche.fiche.last_message_interne = data.message;
          // if (data.usersMessage.length > 0) {
          //   fiche.fiche.users.concat(data.usersMessage);
          //   this.selectedFiche.fiche.users.concat(data.usersMessage);
          // }
        } else {
          fiche.fiche.last_message = data.message;
        }
        this.fiches = this.fiches.sort(this.compareFicheTime);
        // remonter la derniere fiche en haut de liste
        let index = this.fiches.findIndex((f) => f.fiche.id == data.fiche);
        this.fiches.unshift(this.fiches.splice(index, 1)[0]);

        if (this.selectedFiche != null) {
          this.selectedFiche.fiche.files = data.files;
          if (fiche.fiche.id == this.selectedFiche.fiche.id) {
            await MessagerieService.addUserSeenFiche({
              fiche: fiche.fiche.id,
              idUser: this.userId,
              isSuperAdmin: this.user.superAdministrator,
            }).then((res) => {
              fiche.fiche.usersSeen = fiche.fiche.usersSeen.filter(
                (u) => u.idUser != this.userId
              );
              fiche.fiche.usersSeen.push({
                idUser: this.userId,
                nb: 0,
              });
            });
            HotlineBus.$emit('scroll-chat-bottom');
          }
        }
      });
    },

    /**
     * Lors du clic sur le bouton new fiche
     */
    createFiche() {
      this.selectedFiche = null;
      this.createFicheForm = true;
      this.viewSettings = false;
      this.$store.commit('resetStatusFicheDone');
    },
    /**
     * Methode lancée au scroll de la liste pour voir si on récupère les fiches suivantes
     */
    async filterSimpleEndScroll(el) {
      // console.log("here ?")

      if (el.process > 0.9 && !this.loadingScrollSimple && !this.endSimple) {

        this.loadingScrollSimple = true;
        this.skipSimple = this.skipSimple + 30;
        let bases = [];
        if (this.hostGlobale == null) {
          bases = [this.host];
        } else {
          bases = this.hostGlobale.bases.map((b) => b.name);
          bases.push(this.host);
        }

        if (this.arrayFilterChecked.length > 0) {
          this.arrayfilterActivate = [...this.arrayFilterChecked];
          let datas = [];
          this.arrayfilterActivate.forEach((f) => {
            let obj = {
              id: f.id,
              filters: [],
              archived: typeof f.archived != 'undefined' ? f.archived : false,
            };
            f.filter.forEach((type) => {
              if ([1, 8, 9].includes(type.id)) {
                obj.filters.push({ id: type.id, value: type.value });
              }
              if (this.isMasterBase()) {
                if (type.id == 2) {
                  obj.filters.push({
                    id: type.id,
                    value: type.selected.map((s) => s.text),
                  });
                }
              }
              if ([3].includes(type.id)) {
                obj.filters.push({
                  id: type.id,
                  value: type.selected.map((s) => s.value),
                });
              }
              if ([4, 5, 6, 7].includes(type.id)) {
                obj.filters.push({
                  id: type.id,
                  value: type.selected.map((s) => s.value.id),
                });
              }
            });
            datas.push(obj);
          });

          // Récupération des filtres en BDD

          await MessagerieService.filtersAdvanced({
            data: datas,
            rights: this.rightsUser,
            tags: this.tagsUser.map((u) => u._id),
            bases,
            skip: this.skipSimple,
          })
            .then((res) => {
              if (res.length > 0) {
                // res.forEach((f) => {
                //   let checkFiche = this.fiches.find(
                //     (fiche) => fiche.fiche.id == f.fiche.id
                //   );
                //   if (!checkFiche) {
                //     this.fiches.push(f);
                //   }
                // });
                // console.log('this.fiches', this.fiches);
                this.fiches = this.fiches.concat(res);
                // this.$set(this.fiches, this.fiches.length, res);
              } else {
                this.endSimple = true;
              }
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              this.loadingScrollSimple = false;
            });
          for (
            let index = 0;
            index < this.arrayfilterActivate.length;
            index++
          ) {
            this.panelsOpen.push(index);
          }
        } else {
          // const vs = this.$refs["simpleScroll"];
          // const panel = vs.scrollPanelElm;
          // const y = panel.scrollHeight;
          // vs.scrollTo({
          //   y,
          // });
          let objFiches = {
            skip: this.skipSimple,
            rights: this.rightsUser,
            filter_simple: this.filter_simple,
            bases: bases,
          };
          if (!this.rightsUser.hotline) {
            objFiches.tags = this.tagsUser.map((u) => u._id);
            objFiches.tagsname = this.tagsUser.map((u) => u.tag.name);
          }
          this.getFichesFunction(objFiches, 'scroll_simple').then(() => { });
        }
      }
    },
    /**
     * Lors du check d'un filtre dans la liste des filtres de l'user
     */
    filterChecked(filter) {
      if (filter.checked) {
        this.arrayFilterChecked.push(filter);
      } else {
        this.arrayFilterChecked = this.arrayFilterChecked.filter(
          (f) => f.id != filter.id
        );
      }
      this.arrayFilterChecked = this.arrayFilterChecked.sort(
        this.compareString
      );
      this.skipSimple = 0;
      this.endSimple = false;
    },
    /**
     * Permet d'activer les filtres sélectionnés
     */
    async filterRemoving() {
      await MessagerieService.deleteFilter(
        [...this.arrayFilterChecked].map((f) => f.id)
      )
        .then((res) => {
          this.filterUser = res.sort(this.compareString);
          this.arrayFilterChecked = [];
          this.$nSuccess(
            this.$t('messagerie.filtres.delete_dialog.filter_deleted'),
            '',
            1500
          );
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * Permet d'activer les filtres sélectionnés
     */
    async filterActivate() {
      this.panelsOpen = [];
      this.arrayfilterActivate = [...this.arrayFilterChecked];
      let datas = [];
      this.arrayfilterActivate.forEach((f) => {
        let obj = {
          id: f.id,
          filters: [],
          archived: typeof f.archived != 'undefined' ? f.archived : false,
        };
        f.filter.forEach((type) => {
          if ([1, 8, 9].includes(type.id)) {
            obj.filters.push({ id: type.id, value: type.value });
          }
          if (this.isMasterBase()) {
            if (type.id == 2) {
              obj.filters.push({
                id: type.id,
                value: type.selected.map((s) => s.text),
              });
            }
          }
          if ([3].includes(type.id)) {
            obj.filters.push({
              id: type.id,
              value: type.selected.map((s) => s.value),
            });
          }
          if ([4, 5, 6, 7].includes(type.id)) {
            obj.filters.push({
              id: type.id,
              value: type.selected.map((s) => s.value.id),
            });
          }
        });
        datas.push(obj);
      });
      let bases = [];
      if (this.hostGlobale == null) {
        bases = [this.host];
      } else {
        bases = this.hostGlobale.bases.map((b) => b.name);
      }
      this.$store.commit(
        'setPreselectedFilter',
        datas.map((d) => d.id)
      );

      let store = this.$_.pick(this.$store.state, ['preferences']);
      PlaneteOnlineService.postInterface(store).then(() => { });
      // Récupération des filtres en BDD

      await MessagerieService.filtersAdvanced({
        data: datas,
        rights: this.rightsUser,
        tags: this.tagsUser.map((u) => u._id),
        bases,
      })
        .then((res) => {
          this.fiches = [];
          this.fiches = this.fiches.concat(res);
        })
        .catch((err) => {
          console.error(err);
        });
      for (let index = 0; index < this.arrayfilterActivate.length; index++) {
        this.panelsOpen.push(index);
      }
      this.filterAdvancedActivate = true;
    },
    /**
     * Fonction de changement de filtre avancé ou simple
     */
    async changeFilterType() {
      this.filterType = !this.filterType;
      this.filterAdvancedActivate = false;
      this.arrayfilterActivate = [];
      this.arrayFilterChecked = [];
      if (this.preferences.preselected_filters) {
        this.$store.commit('setPreselectedFilter', []);

        let store = this.$_.pick(this.$store.state, ['preferences']);
        await PlaneteOnlineService.postInterface(store);
      }
      this.skipSimple = 0;
      this.endSimple = false;
      this.fiches = [];

      if (this.filterType) {
        this.filterUser.forEach((f) => (f.checked = false));
      } else {
        this.loading = true;
        let objFiches = {
          skip: this.skipSimple,
          rights: this.rightsUser,
          filter_simple: this.filter_simple,
        };
        if (!this.rightsUser.hotline) {
          objFiches.tags = this.tagsUser.map((u) => u._id);
          objFiches.tagsname = this.tagsUser.map((u) => u.tag.name);
          if (this.hostGlobale == null) {
            objFiches.bases = [this.host];
          } else {
            objFiches.bases = this.hostGlobale.bases.map((b) => b.name);
            objFiches.bases.push(this.host);
          }
        }
        this.getFichesFunction(objFiches).then(() => { });
      }
    },
    /**
     * Fonction de changement depassage export a fiche
     */
    async changeViewExport() {
      let rights = {};
      if (!this.rightsUser.hotline) {
        rights.tags = this.tagsUser.map((u) => u._id);
        if (this.hostGlobale == null) {
          this.bases = [this.host];
        } else {
          this.bases = this.hostGlobale.bases.map((b) => b.name);
          this.bases.push(this.host);
        }
      }
      this.viewExport = !this.viewExport;
    },
    /**
     * Fonction pour switch vue
     */
    async changeViewSettings() {
      this.selectedFiche = null;
      this.createFicheForm = false;
      this.viewSettings = !this.viewSettings;
    },
    /**
     * Permet d'avoir le nombre d'autres utilisateurs dans la fiche
     * @var fiche La fiche
     */
    getNbUserFiches(fiche) {
      return fiche.fiche.users
        .filter((u) => {
          if (u.id != this.userId) {
            return u;
          }
          if (u.id != fiche.fiche.userCreate.id) {
            return u;
          }
        })
        .sort(this.compareUsers).length;
    },
    /**
     * Permet de savoir depuis combien de temps une date est arrivée
     * @var time timestamp
     */
    convertUnixToFromNow(time) {
      return moment(time * 1000)
        .fromNow(true)
        .replace('minutes', 'min');
    },
    /**
     * Permet d'avoir la couleur de l'état
     */
    getColorEtat(etat) {
      if (etat.id == 3) {
        return 'red';
      }
      if ([1, 2].includes(etat.id)) {
        return 'orange';
      }
      if ([4, 5].includes(etat.id)) {
        return 'green';
      }
    },
    /**
     * Permet de savoir quel dernier message afficher dans la liste de gauche
     * @var fiche la fiche
     */
    lastMessage(fiche) {
      if (
        typeof fiche.last_message_interne != 'undefined' &&
        typeof this.getMessagerieData().rightsUser.hotline != 'undefined'
      ) {
        return fiche.last_message_interne.time > fiche.last_message.time
          ? fiche.last_message_interne
          : fiche.last_message;
      } else {
        return fiche.last_message;
      }
    },
    /**
     * Permet de reset les filtre selectionnés
     */
    returnSelectFilter() {
      this.filterUser.forEach((f) => (f.checked = false));
      this.filterAdvancedActivate = false;
      this.arrayfilterActivate = [];
      this.arrayFilterChecked = [];
      this.$store.commit('setPreselectedFilter', []);

      let store = this.$_.pick(this.$store.state, ['preferences']);
      PlaneteOnlineService.postInterface(store);
    },
    addTypeFilter(filter) {
      if (filter.checked) {
        this.filterTypeArrayChecked.push(filter);
      } else {
        this.filterTypeArrayChecked = this.filterTypeArrayChecked.filter(
          (f) => f.id != filter.id
        );
      }
      for (let index = 0; index < this.filterTypeArrayChecked.length; index++) {
        const element = this.filterTypeArrayChecked[index];
        let filter = this.filterTypeArray.find((f) => f.id == filter.id);
        filter.order = index;
      }
    },
    /**
     * Permet de selectionner tout les items d'un filtre
     * @var filter le filtre
     */
    selectAllFilter(filter) {
      this.$nextTick(() => {
        if (filter.selected.length == filter.items.length) {
          filter.selected = [];
        } else {
          filter.selected = filter.items.slice();
        }
      });
    },
    /**
     * Permet de vérifier qu'un filtre n'a pas le meme nom que les autres
     */
    checkNotSameNameFilter() {
      let filter = this.filterUser.find((f) => f.name == this.filterInputTitle);
      filter != null
        ? (this.sameNameFilter = true)
        : (this.sameNameFilter = false);
    },
    /**
     * Permet de mettre à jour ou ajouter le filtre
     */
    async saveFilter() {
      this.loaderFilterSend = true;
      this.errorFilterForm = [];
      if (this.filterInputTitle == '') {
        this.errorFilterForm.push({
          input: 'messagerie.filtres.form.title',
          error: 'empty',
          inputTrad: false,
        });
      }
      if (this.filterTypeArrayDrop.length == 0) {
        this.errorFilterForm.push({
          input: 'messagerie.filtres.form.filter',
          error: 'empty',
          inputTrad: false,
        });
      }

      this.filterTypeArrayDrop.forEach((f) => {
        if ([1, 8, 9].includes(f.id)) {
          if (f.value == '') {
            this.errorFilterForm.push({
              input: f.name,
              error: 'empty',
              inputTrad: true,
            });
          }
        } else {
          if (f.selected.length == 0) {
            this.errorFilterForm.push({
              input: f.name,
              error: 'empty',
              inputTrad: true,
            });
          }
        }
      });
      if (this.errorFilterForm.length == 0) {
        let filtre = {
          id: this.filterUpdate == null ? this.$uuid.v4() : this.filterUpdate,
          checked: false,
          name: this.filterInputTitle,
          archived: this.filterAddArchived,
          filter: this.filterTypeArrayDrop,
        };
        if (this.filterUpdate != null) {
          filtre.update = true;
        }

        await MessagerieService.postFilter(filtre)
          .then((res) => {
            this.arrayFilterChecked = [];
            this.filterUser = res.sort(this.compareString);
            this.filterTypeArrayDrop.forEach((f) => {
              this.filterTypeArray.push(f);
            });
            this.filterTypeArrayDrop = [];
            this.filterTypeArray.forEach((f) => {
              if ([1, 8, 9].includes(f.id)) {
                f.value = '';
              } else {
                f.selected = [];
              }
            });
            this.filterInputTitle = '';
            this.formFilter = false;
            this.filter;
            if (this.filterUpdate != null) {
              this.$nSuccess(
                this.$t('messagerie.filtres.filter_updated'),
                '',
                1500
              );
              this.filterUpdate = null;
            } else {
              this.$nSuccess(
                this.$t('messagerie.filtres.filter_added'),
                '',
                1500
              );
              // this.filterUpdate = null;
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.loaderFilterSend = false;
          });
      }
      this.loaderFilterSend = false;
    },
    // permet que lorsqu'on clique sur le bouton annuler du filtre, le filtre soit bien annulé et on ne le retrouve pas dans la liste des filtres en voulant créer un nouveau filtre
    cancelDialogFilter() {
      this.formFilter = false;
      // reset des filtres
      this.filterTypeArray.forEach((f) => {
        if ([1, 8, 9].includes(f.id)) {
          f.value = '';
        } else {
          f.selected = [];
        }
      });
      this.filterTypeArrayDrop = [];
      this.filterInputTitle = '';
      this.filterUpdate = null;
    },
    /**
     * Permet d'ouvrir la modal pour modifier le filtre
     * @var filter le filtre en question
     */
    updateFilter(filter) {
      this.filterUpdate = filter.id;
      this.filterInputTitle = filter.name;
      filter.filter.forEach((f) => {
        this.filterTypeArrayDrop.push(f);
        this.filterTypeArray = this.filterTypeArray.filter(
          (fType) => fType.id != f.id
        );
      });
      this.formFilter = true;
    },
    /**
     * Supprime le filtre
     * @var filter Le filtre en question
     */
    async removeFilter(filter) {
      await MessagerieService.deleteFilter([filter.id])
        .then((res) => {
          this.filterUser = res.sort(this.compareString);
          this.arrayFilterChecked = [];
          this.$nSuccess(
            this.$t('messagerie.filtres.delete_dialog.filter_deleted'),
            '',
            1500
          );
          this.filterDeleting = null;
          this.dialogRemoveFilter = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * Génère un id uniq
     * @var prefix Le préfix si besoin
     */
    uniqid(prefix = '', random = false) {
      const sec = Date.now() * 1000 + Math.random() * 1000;
      const id = sec
        .toString(16)
        .replace(/\./g, '')
        .padEnd(14, '0');
      return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}` : ''
        }`;
    },
    /**
     * Fonction enclenchée au select d'une fiche
     * @var fiche La fiche
     */
    selectFiche(fiche) {
      if (this.newFicheInWriting()) {
        this.selectedFicheBeforeStopNewFiche = fiche;
        this.dialogStopNewFiche = true;
      } else {
        if (this.chatInWriting()) {
          HotlineBus.$emit('Chat-reset-content');
        }
        this.selectFicheAfterCheck(fiche);
      }
    },
    async selectFicheAfterCheck(fiche) {
      this.resetFiche();
      if (!this.stopNewFicheDetailled) {
        if (this.selectedFiche != null) {
          delete this.selectedFiche.fiche.messages;
        }
        this.selectedFiche = fiche;
        MessagerieService.getMessagesFiche({
          fiche: this.selectedFiche.fiche.id,
        }).then((res) => {
          this.$set(this.selectedFiche.fiche, 'messages', res.fiche.messages);
          // // si le dernier message est un message interne et qu'il n'est pas vu et qu 'il est different de undefined'
          if (
            this.selectedFiche.fiche.last_message_interne != undefined &&
            this.selectedFiche.fiche.last_message_interne.isSeen == false
          ) {
            // je met le message interne en vu
            this.$set(
              this.selectedFiche.fiche.last_message_interne,
              'isSeen',
              true
            );
          }

          // ------------- mohamed ------------- //
          // Émettre l'événement "load-draft" avec l'ID de la fiche
          this.$emit('load-draft', this.selectedFiche.fiche.id);

          // j'envoi l'id de la fiche courante au store
          this.$store.commit('setCurrentFicheId', this.selectedFiche.fiche.id);
          if (
            fiche.fiche.etat.text == 'etat.demande_terminee' ||
            fiche.fiche.etat.text == 'etat.demande_cloturee'
          ) {
            this.$emit('setCurrentFicheId', this.selectedFiche.fiche.id);

            this.$store.commit('setStatusFicheDone', {
              ficheId: this.selectedFiche.fiche.id,
              status: true,
            });
          } else {
            this.$store.commit('setStatusFicheDone', {
              ficheId: this.selectedFiche.fiche.id,
              status: false,
            });
          }
        });
      }

      this.stopNewFicheDetailled = false;
    },

    async dataUrlToFile(dataUrl, fileName, mime) {
      const res = await fetch(dataUrl);
      const blob = await res.blob();
      return new File([blob], fileName, { type: mime });
    },
    /**
     * Tri tableau sur name
     */
    compareString(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    /**
     * Tri du tableau des users
     */
    compareUsers(a, b) {
      if (a.lastname < b.lastname) {
        return -1;
      }
      if (a.lastname > b.lastname) {
        return 1;
      }
      return 0;
    },
    /**
     * Tri du tableau des fiches sur le dernier message recu
     */
    compareFicheTime(a, b) {
      let a_time = 0;
      let b_time = 0;
      if (this.main_hotline_base) {
        if (a.fiche.last_message_interne) {
          a_time =
            a.fiche.last_message.time > a.fiche.last_message_interne.time
              ? a.fiche.last_message.time
              : a.fiche.last_message_interne.time;
        } else {
          a_time = a.fiche.last_message.time;
        }
        if (b.fiche.last_message_interne) {
          b_time =
            b.fiche.last_message.time > b.fiche.last_message_interne.time
              ? b.fiche.last_message.time
              : b.fiche.last_message_interne.time;
        } else {
          b_time = b.fiche.last_message.time;
        }
        return a_time > b_time;
      }
      return a.fiche.last_message.time > b.fiche.last_message.time;
    },
    async transformImageMessage(wrapper, type, base64imgs = null) {
      if (type == 'new_fiche') {
        await Promise.all(
          Object.entries(wrapper.getElementsByTagName('img')).map(async (i) => {
            let mime = '';
            let ext = '';
            if (i[1].src.includes('image/jpeg')) {
              mime = 'image/jpeg';
              ext = '.jpg';
            }
            if (i[1].src.includes('image/png')) {
              mime = 'image/png';
              ext = '.png';
            }
            let new_name = 'img-' + this.$uuid.v4() + ext;
            let file = await this.dataUrlToFile(i[1].src, new_name, mime);
            base64imgs.push(file);
            if (type == 'new_fiche') {
              this.contentNewFiche = this.contentNewFiche.replace(
                i[1].src,
                process.env.VUE_APP_BASEURL +
                '/messagerie/img-content/' +
                new_name
              );
            }
            i[1].src = 'img-' + this.$uuid.v4();
          })
        );
      }
    },
    /**
     * Permet d'envoyer la fiche, avec validation
     */
    async sendFiche(ficheForm) {
      this.errorsNewFiches = [];
      let fiche = { ...this.newFiche, ...ficheForm };
      this.contentNewFiche = fiche.content;
      fiche.content = undefined;

      this.contentNewFiche = Parsing.urlify(this.contentNewFiche);

      //Validation
      if (this.contentNewFiche == undefined) {
        this.errorsNewFiches.push({ input: 'messages', error: 'empty' });
      }
      if (fiche.objet == '') {
        this.errorsNewFiches.push({ input: 'object', error: 'empty' });
      }
      if (fiche.url == null) {
        this.errorsNewFiches.push({ input: 'url', error: 'empty' });
      }
      if (fiche.module == null) {
        this.errorsNewFiches.push({ input: 'module', error: 'empty' });
      }
      if (fiche.ordre_priorite == null) {
        this.errorsNewFiches.push({ input: 'ordre_priorite', error: 'empty' });
      }
      if (fiche.impact == null) {
        this.errorsNewFiches.push({ input: 'impact', error: 'empty' });
      }
      if (fiche.type_demande == null) {
        this.errorsNewFiches.push({ input: 'type_demande', error: 'empty' });
      }
      if (this.errorsNewFiches.length == 0) {
        //Ajout de l'user qui crée la fiche dans la liste des users
        let userFind = fiche.users.find(
          (u) => u.id == this.userId && u.url == this.getDns()
        );
        if (userFind == null) {
          fiche.users.push(
            await this.removeDimensionInutileUser(
              this.users.find((u) => u.id == this.userId)
            )
          );
        }
        //Récupération de la base et du client
        let client_fiche = null;
        if (typeof fiche.url.value == 'undefined') {
          fiche.url = this.bases_clientsArraySelectable.find(
            (b) => b.text == fiche.url.name
          );
        }

        this.bases_clients.forEach((client) => {
          let baseHost = client.bases.find(
            (b) => b.name == fiche.url.value.name
          );
          if (baseHost != undefined) {
            client_fiche = client;
          }
        });
        fiche.client = client_fiche.name;
        fiche.id = this.$uuid.v4();

        fiche.userCreate = await this.removeDimensionInutileUser(
          this.informations
        );
        let time = moment().unix();
        fiche.usersSeen = [
          {
            nb: 0,
            idUser: this.userId + 1,
            isSuperAdmin: this.user.superAdministrator,
          },
        ];
        fiche.etat = this.etats.find((e) => e.value.id == 3);
        let message;
        let user = this.user;
        user = await this.removeDimensionInutileUser(this.informations);
        let wrapper = document.createElement('div');
        wrapper.innerHTML = this.contentNewFiche;
        let base64imgs = [];
        await this.transformImageMessage(wrapper, 'new_fiche', base64imgs);
        if (typeof fiche.files != 'undefined') {
          if (fiche.files.length > 0) {
            message = {
              id: 'msg-' + this.$uuid.v4(),
              content: this.contentNewFiche,
              time: time,
              user,
              idUser: this.userId,
              type: 0,
              files: fiche.files.map((f) => f.new_name),
            };
          }
        } else {
          fiche.files = [];
          message = {
            id: 'msg-' + this.$uuid.v4(),
            content: this.contentNewFiche,
            time: time,
            user,
            idUser: this.userId,
            type: 0,
          };
        }

        fiche.messages = [];
        fiche.last_message = message;
        fiche.messages.push(message);
        // Récupère le nom du module en français pour GTP
        if (this.$i18n.locale != 'fr') {
          let moduleResponse = await UserService.getModules(this.$i18n.locale);
          if (moduleResponse) {
            moduleResponse = Object.values(moduleResponse);
            moduleResponse.push({
              id: -1,
              name: this.$t('app.modules.other'),
              subModules: [],
            });
            let module = moduleResponse.find(
              (mod) => mod.id == fiche.module.id
            );
            let subModule = module.subModules.find(
              (subMod) => subMod.id == fiche.sous_module.id
            );
            fiche.module = module;
            fiche.sous_module = subModule;
          }
        }
        fiche.archived = false;
        fiche.new_fiche = true;
        fiche.label_module = '';
        fiche.comment = '';
        let formData = new FormData();
        formData.append('new_fiche', JSON.stringify(fiche));
        base64imgs.forEach((i) => {
          formData.append('images', i);
        });
        let $this = this;
        await MessagerieService.addFiches(formData)
          .then((res) => {
            $this.resetFiche();
          })
          .catch((err) => console.error(err));
      }
    },
    resetFiche() {
      this.files = [];
      this.contentNewFiche = ``;
      this.newFiche = {
        id: null,
        new_fiche: true,
        archived: false,
        users: [],
        userCreate: null,
        objet: '',
        phone: '',
        etat: null,
        module: null,
        sous_module: null,
        type_demande: null,
        url: this.getDns(),
        impact: null,
        ordre_priorite: null,
        messages: [],
        comment: '',
        label_module: '',
        phone_be_contacted: false,
        content: '',
      };
      this.createFicheForm = false;
      this.viewSettings = false;
      this.selectedFicheBeforeStopNewFiche = null;
      this.dialogStopNewFiche = false;
    },
    /**
     * Concatène le nom de l'user
     * @var userId Id de l'user
     */
    usernameUser(user) {
      return user.firstname + ' ' + user.lastname.toUpperCase();
    },
    /**
     * Fonction qui permet de changer les données de la fiche
     * @var type le type de la données par exemple état
     */
    async changeDataFiche(type, value) {
      let user = this.user;
      delete user.photo;
      let datas = {
        fiche: this.selectedFiche.fiche.id,
        type,
        object: null,
        user,
      };
      datas.object = value;
      await MessagerieService.changeParamsFiche(datas).then((res) => {
        if (type == 'etat') {
          this.selectedFiche.fiche.etat = value;
          this.$nSuccess(this.$t('messagerie.chat.change_state_success'));
        }
        if (type == 'priorite') {
          this.selectedFiche.fiche.ordre_priorite = value;
          this.$nSuccess(this.$t('messagerie.chat.change_property_success'));
        }
        if (type == 'impact') {
          this.selectedFiche.fiche.impact = value;
          this.$nSuccess(this.$t('messagerie.chat.change_impact_success'));
        }
        if (type == 'type_demande') {
          this.selectedFiche.fiche.type_demande = value;
          this.$nSuccess(
            this.$t('messagerie.chat.change_type_demande_success')
          );
        }
      });
    },
    /**
     * Permet d'avoir un select des super admin dans la modal des messages internes
     */
    openDialogInterne() {
      this.messageInterne.selectUsers = this.users.filter(
        (u) => u.superAdministrator
      );
    },

    /**
     * Permet d'ajouter des users à une fiche
     */
    async addUsersFicheFunction() {
      let users = this.users.filter((u) =>
        this.addUsersFiche.users.includes(u.id)
      );
      await MessagerieService.addRemoveUsersFiche({
        fiche: this.selectedFiche.fiche.id,
        users: users,
        type: 'add',
      }).then((res) => {
        this.selectedFiche.fiche.users = this.selectedFiche.fiche.users.concat(
          users
        );
        this.addUsersFiche.users = [];
      });
    },
    /**
     * Permet d'ajouter des tag à une fiche
     */
    async addTagsFicheFunction() {
      let tags = this.tagsPlaneteOnline.filter((t) =>
        this.addTagsFiche.tags.includes(t._id)
      );
      await MessagerieService.addRemoveTagsFiche({
        fiche: this.selectedFiche.fiche.id,
        tags: tags,
        type: 'add',
      }).then((res) => {
        if (typeof this.selectedFiche.fiche.tags == 'undefined') {
          this.selectedFiche.fiche.tags = [];
        }
        this.selectedFiche.fiche.tags = this.selectedFiche.fiche.tags.concat(
          tags
        );
        this.addTagsFiche.tags = [];
      });
    },
    /**
     * Permet de retirer des users d'une fiche
     */
    async removeUsersFicheFunction() {
      if (
        confirm(
          'êtes vous sur de vouloir supprimer les ' +
          this.removeUsersFiche.length +
          ' utilisateurs de la fiche ?'
        )
      ) {
        await MessagerieService.addRemoveUsersFiche({
          fiche: this.selectedFiche.fiche.id,
          users: this.removeUsersFiche.map((u) => u.id),
          type: 'delete',
        }).then((res) => {
          this.selectedFiche.fiche.users = this.selectedFiche.fiche.users.filter(
            (u) => !this.removeUsersFiche.includes(u)
          );
          this.removeUsersFiche = [];
        });
      }
    },
    /**
     * Permet de retirer des tags d'une fiche
     */
    async removeTagsFicheFunction() {
      if (
        confirm(
          'êtes vous sur de vouloir supprimer les ' +
          this.removeTagsFiche.length +
          ' tags de la fiche ?'
        )
      ) {
        await MessagerieService.addRemoveTagsFiche({
          fiche: this.selectedFiche.fiche.id,
          tags: this.removeTagsFiche.map((u) => u._id),
          type: 'delete',
        }).then((res) => {
          this.selectedFiche.fiche.tags = this.selectedFiche.fiche.tags.filter(
            (u) => !this.removeTagsFiche.map((u) => u._id).includes(u._id)
          );
          this.removeTagsFiche = [];
        });
      }
    },
  },
  watch: {
    async currentLang() {
      // ForceUpdate sur le tiptap
      this.keyLangCheat = this.$uuid.v4();
    },
    //Lors du changement de langue on refresh les filtres
    modules() {
      let filterModule = this.filterTypeArray.find((f) => f.id == 3);
      if (!filterModule) {
        filterModule = this.filterTypeArrayDrop.find((f) => f.id == 3);
      }
      filterModule.items = [];
      this.modules.forEach((pm) => {
        filterModule.items.push({ value: pm, text: pm.name });
        pm.subModules.forEach((sm) => {
          filterModule.items.push({
            value: sm,
            text: '[' + pm.name + '] - ' + sm.name,
          });
        });
      });
    },
    /**
     * Lorsque qu'une fiche est selectionné on attends 0.2 secondes avant de faire le scroll sinon ca ne fonctionne pas
     */
    async selectedFiche() {
      if (this.selectedFiche) {
        HotlineBus.$emit('Chat-reset-attachment');
        await MessagerieService.addUserSeenFiche({
          fiche: this.selectedFiche.fiche.id,
          idUser: this.userId,
          isSuperAdmin: this.user.superAdministrator,
        }).then((res) => {
          this.selectedFiche.fiche.usersSeen = res.fiche.usersSeen;
        });
        setTimeout(() => {
          HotlineBus.$emit('scroll-chat-bottom');
        }, 200);
      }
    },
  },
  mounted: async function () {
    // Patch 19 Mai 2022 => Ne pas afficher le filtre "url des bases" si base client
    if (!this.isMasterBase()) {
      this.filterTypeArray = this.filterTypeArray.filter((f) => f.id != 2);
    }

    let that = this;
    document.addEventListener('click', function (e) {
      if (typeof e.target.src != 'undefined') {
        if (e.target.src.includes('http') || e.target.src.includes('base64')) {
          that.dialogLightBox = true;
          that.imgLightBoxLoading = true;
          that.imgLightbox = e.target.src;
        }
      }
    });
    //Event du header
    HotlineBus.$on('MessagerieHeader-create-fiche', () => this.createFiche());
    HotlineBus.$on('MessagerieHeader-change-view', (val) =>
      this.changeView(val)
    );
    HotlineBus.$on(
      'MessagerieHeader-open-form-filter',
      () => (this.formFilter = true)
    );
    HotlineBus.$on('MessagerieHeader-change-filter-type', () =>
      this.changeFilterType()
    );
    HotlineBus.$on('MessagerieHeader-change-view-export', () =>
      this.changeViewExport()
    );
    HotlineBus.$on('MessagerieHeader-change-view-settings', () =>
      this.changeViewSettings()
    );
    HotlineBus.$on('MessagerieHeader-change-filter-simple', (val) => {
      this.filter_simple = val;
      this.skipSimple = 0;
      this.changeFilterSimple();
    });
    HotlineBus.$on('MessagerieHeader-change-filter-users', (val) => {
      this.filter_users = val;
      this.skipUsers = 0;
      this.changeFilterUsers();
    });
    HotlineBus.$on('MessagerieHeader-change-filter-tags', (val) => {
      this.filter_tags = val;
      this.skipTags = 0;
      this.changeFilterTags();
    });
    HotlineBus.$on('MessagerieHeader-change-reset-filter-user', () => {
      this.resetFilters();
    });
    HotlineBus.$on('MessagerieHeader-filter-activate', () =>
      this.filterActivate()
    );
    HotlineBus.$on('MessagerieHeader-filter-remove', () =>
      this.filterRemoving()
    );
    HotlineBus.$on('MessagerieHeader-filter-checked', (val) =>
      this.filterChecked(val)
    );
    HotlineBus.$on('MessagerieHeader-return-select', () =>
      this.returnSelectFilter()
    );
    HotlineBus.$on('MessagerieHeader-update-filter', (val) =>
      this.updateFilter(val)
    );
    HotlineBus.$on('MessagerieHeader-remove-filter', (val) => {
      this.dialogRemoveFilter = true;
      this.filterDeleting = val;
    });
    //Event FormFiche
    HotlineBus.$on('FormFiche-open-dialog-piece-jointe', () => {
      this.dialogPieceJointe = true;
    });
    HotlineBus.$on('FormFiche-stop-new-fiche', (val) => {
      this.newFiche = Object.assign({}, val);
      this.contentNewFiche = val.content;
      if (this.newFicheInWriting()) {
        this.dialogStopNewFiche = true;
        this.stopNewFicheDetailled = true;
      } else {
        this.createFicheForm = false;
      }
    });

    HotlineBus.$on('archived', (fiche) => this.archivedFiche(fiche));
    HotlineBus.$on('search_fiche_ticket', (obj) => {
      this.searchTicket = obj.searchTicket;
      this.searchFicheTicket(obj.clear, obj.enter);
      this.resetFilters(obj.clear, obj.enter);
    });
    document.title = 'Messagerie | planete-online';
    if (!this.isMasterBase()) {
      this.filterTypeArray = this.filterTypeArray.filter((f) => f.id != 2);
    }
    if (this.asRightDetails) {
      this.filterTypeArray = this.filterTypeArray.filter((f) => f.id != 9);
    }
    // Mise en place des params
    let filterModule = this.filterTypeArray.find((f) => f.id == 3);
    this.modules.forEach((pm) => {
      filterModule.items.push({ value: pm, text: pm.name });
      pm.subModules.forEach((sm) => {
        filterModule.items.push({
          value: sm,
          text: '[' + pm.name + '] - ' + sm.name,
        });
      });
    });
    let filterOrdrePriorite = this.filterTypeArray.find((f) => f.id == 4);
    parametrages_messagerie.ordre_priorites.forEach((o) => {
      filterOrdrePriorite.items.push({
        text: this.$t('messagerie.parametrages.' + o.cleTraduction),
        value: o,
      });
      this.ordre_priorites.push({ text: o.cleTraduction, value: o });
    });
    let filterEtat = this.filterTypeArray.find((f) => f.id == 6);
    parametrages_messagerie.etats.forEach((o) => {
      filterEtat.items.push({
        text: this.$t('messagerie.parametrages.' + o.cleTraduction),
        value: o,
      });
      this.etats.push({ text: o.cleTraduction, value: o });
    });
    let filterTypeDemande = this.filterTypeArray.find((f) => f.id == 5);
    parametrages_messagerie.type_demandes.forEach((o) => {
      filterTypeDemande.items.push({
        text: this.$t('messagerie.parametrages.' + o.cleTraduction),
        value: o,
      });
      this.type_demandes.push({ text: o.cleTraduction, value: o });
    });
    let filterImpact = this.filterTypeArray.find((f) => f.id == 7);
    parametrages_messagerie.impacts.forEach((o) => {
      filterImpact.items.push({
        text: this.$t('messagerie.parametrages.' + o.cleTraduction),
        value: o,
      });
      this.impacts.push({ text: o.cleTraduction, value: o });
    });
    this.filters_simple = parametrages_messagerie.filter_simple;
    this.filter_simple = this.filters_simple.find((f) => f.id == 2);

    let $this = this;
    PlaneteOnlineService.getInterface().then((response) => {
      if (response.preferences.preselected_filters_simple) {
        this.filter_simple = response.preferences.preselected_filters_simple;
      }
    });

    // Users
    await UserService.getUsers().then((res) => {
      this.users = res.sort(this.compareUsers);
    });
    //Récupération des paramètres
    await MessagerieService.getParamsMessagerie()
      .then((res) => {
        this.filterUser = res.filters.sort(this.compareString);
        this.token_rights = res.token_rights;
        this.host = res.host;
        this.$store.commit('setBase', {
          base: this.host,
        });
        // this.tagsPlaneteOnline = res.tags;
        this.tagsPlaneteOnline = Object.values(res.tags);

        if (res.user_tags && res.user_tags.length > 0) {
          this.rightsUser.hotline = false;

          this.rightsUser = res.user_tags[0].tag.rights;
          res.user_tags.forEach((tag) => {
            if (this.isMasterBase() && tag.tag.default) {
              this.rightsUser.hotline = true;
            }

            if (tag.tag.detailed_view) {
              this.rightsUser.detailed_view = true;
            }
            if (tag.tag.filter_detail) {
              this.rightsUser.filter_detail = true;
            }
            if (!this.rightsUser.answerClient && tag.tag.rights.answerClient) {
              this.rightsUser.answerClient = tag.tag.rights.answerClient;
            }
            if (!this.rightsUser.seeAll && tag.tag.rights.seeAll) {
              this.rightsUser.seeAll = tag.tag.rights.seeAll;
            }
          });
          this.tagsUser = res.user_tags;
        }
      })
      .catch((err) => {
        console.error(err);
      });
    let urlFixtures = [];
    // Récupération des bases clients
    await PlaneteOnlineService.getBases2().then((res) => {
      this.bases_clients = res.sort(this.compareString);

      let itemsBases = [];
      this.bases_clients.forEach((client) => {
        if (that.isMasterBase()) {
          itemsBases.push({ header: client.name });
          let client_bases = client.bases.sort(this.compareString);
          client_bases.forEach((base) => {
            itemsBases.push({ text: base.name, value: base });
            urlFixtures.push({ text: base.name, value: base });
          });
        } else {
          let baseHost = client.bases.find((b) => b.name == this.host);

          if (baseHost != undefined) {
            if (baseHost.isGlobale == 1) {
              this.hostGlobale = baseHost;
              this.hostGlobale.bases = [];
              this.hostGlobale.bases = client.bases.filter(
                (b) => b.globale == this.hostGlobale.name
              );
              itemsBases.push({ header: client.name });
              let client_bases = client.bases.sort(this.compareString);
              client_bases.forEach((base) => {
                itemsBases.push({ text: base.name, value: base });
                urlFixtures.push({ text: base.name, value: base });
              });
            } else {
              itemsBases.push({ text: baseHost.name, value: baseHost });
              urlFixtures.push({ text: baseHost.name, value: baseHost });
            }
          }
        }
      });

      this.bases_clientsArraySelectable = itemsBases;
    });

    if (await this.isMasterBase()) {
      this.main_hotline_base = true;
    }

    let objFiches = {
      skip: this.skipSimple,
      rights: this.rightsUser,
      filter_simple: this.filter_simple,
    };
    if (!this.rightsUser.hotline) {
      objFiches.tags = this.tagsUser.map((u) => u._id);
      objFiches.tagsname = this.tagsUser.map((u) => u.tag.name);
      //console.log(objFiches)
      if (this.hostGlobale == null) {
        objFiches.bases = [this.host];
      } else {
        objFiches.bases = this.hostGlobale.bases.map((b) => b.name);
        objFiches.bases.push(this.host);
      }
    }
    if (this.rightsUser.detailed_view) {
      if (typeof this.preferences.default_view != 'undefined') {
        if (this.preferences.default_view == 'details') {
          this.detailledView = true;
        } else {
          this.detailledView = false;
        }
      } else {
        this.detailledView = false;
      }
    } else {
      this.detailledView = false;
    }

    //Ici soit on va chercher les fiches du filtres présélectionnés où on va chercher les récentes
    if (this.$route.query.ticket) {
      this.selectTicketFiche(this.$route.query.ticket);
    } else {
      if (this.preferences.preselected_filters) {
        if (this.preferences.preselected_filters.length > 0) {
          let filters_preselected = null;
          filters_preselected = this.filterUser.filter(
            (f) =>
              Object.values(this.preferences.preselected_filters).indexOf(
                f.id
              ) > -1
          );
          this.arrayFilterChecked = filters_preselected;
          this.arrayFilterChecked = this.arrayFilterChecked.sort(
            this.compareString
          );
          this.arrayfilterActivate = filters_preselected;
          this.filterType = true;
          let datas = [];
          filters_preselected.forEach((f) => {
            let obj = {
              id: f.id,
              filters: [],
              archived: typeof f.archived != 'undefined' ? f.archived : false,
            };
            f.filter.forEach((type) => {
              if ([1, 8, 9].includes(type.id)) {
                obj.filters.push({ id: type.id, value: type.value });
              }
              if (this.isMasterBase()) {
                if (type.id == 2) {
                  obj.filters.push({
                    id: type.id,
                    value: type.selected.map((s) => s.text),
                  });
                }
              }
              if ([3].includes(type.id)) {
                obj.filters.push({
                  id: type.id,
                  value: type.selected.map((s) => s.value),
                });
              }
              if ([4, 5, 6, 7].includes(type.id)) {
                obj.filters.push({
                  id: type.id,
                  value: type.selected.map((s) => s.value.id),
                });
              }
            });
            datas.push(obj);
          });
          let bases = [];
          if (this.hostGlobale == null) {
            bases = [this.host];
          } else {
            bases = this.hostGlobale.bases.map((b) => b.name);
          }
          // Récupération des filtres en BDD
          await MessagerieService.filtersAdvanced({
            data: datas,
            rights: this.rightsUser,
            tags: this.tagsUser.map((u) => u._id),
            bases,
          })
            .then((res) => {
              this.fiches = [];
              this.fiches = this.fiches.concat(res);
            })
            .catch((err) => {
              console.error(err);
            });
          this.filterAdvancedActivate = true;
        } else {
          this.getFichesFunction(objFiches).then(() => { });
        }
      } else {
        this.loading = true;
        this.getFichesFunction(objFiches).then(() => { });
      }
    }

    // Génération de fixtures
    // await MessagerieService.fixturesFiches({
    //   url: urlFixtures,
    //   users: this.users,
    //   params: parametrages_messagerie,
    //   module: this.modules,
    // }).catch((err) => {
    //   console.error(err);
    // });
    this.connectSocketIO();
    this.$store.commit('setMessagerieData', {
      impacts: this.impacts,
      etats: this.etats,
      ordre_priorites: this.ordre_priorites,
      type_demandes: this.type_demandes,
      rightsUser: this.rightsUser,
      users: this.users,
      tagsPlaneteOnline: this.tagsPlaneteOnline,
      host: this.host,
    });
  },
  created: function () {
    this.user = this.informations;
    this.user.url = this.getDns();
    this.userId = parseInt(this.informations.id);
  },
};
</script>

<style lang="scss">
$violet: var(--v-primary-base);
$violet-light: var(--v-secondary-base);

.v-list-item__mask {
  background: rgba(123, 78, 142, 0.3) !important;
}

//noinspection CssUnusedSymbol,CssUnusedSymbol
.v-skeleton-loader__avatar,
.v-skeleton-loader__text {
  opacity: 25%;
  background: linear-gradient(90deg,
      var(--v-tertiary-base) 10%,
      var(--v-secondary-base) 90%) !important;
}

.hotline-container {
  display: grid;
  grid-template-columns: 2fr 4fr;
  height: 100%;
  grid-gap: 12px;
}

.hotline-container-detailled {
  display: grid;
  height: 100%;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.card-three {
  grid-column: 2;
  grid-row: 1;
}

.card-one {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
}

.tchat-view {
  height: 60%;
}

.vue-scroll-list-filter {
  height: 730px !important;
}

.vue-scroll-list-filter-item {
  height: 600px !important;
}

.vue-scroll-list-remove-user {
  height: 400px !important;
}

.messagerie-chat--filter {
  border-radius: 5px;
  padding: 5px 15px 5px 15px;
  margin-bottom: 20px;

  .messagerie-chat--filter--header {
    display: flex;
    justify-content: space-between;
  }

  .messagerie-chat--filter--content--advanced {
    .messagerie-chat--filter--content--advanced--data {
      .v-btn {
        margin-top: 15px;
      }

      .filter--action {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        button {
          margin-bottom: 9px;
        }
      }
    }

    .messagerie-chat--filter--content--advanced--listfilter {
      .filter {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.messagerie-list--item {
  padding-left: 8px;

  &.clicked {
    border-left: solid 8px var(--v-primary-base);
    padding-left: 0;
    background-color: #ccc !important;
  }

  &.new_fiche {
    border-left: solid 8px var(--v-warning-base);
    padding-left: 0;
  }

  &.archived {
    border-left: solid 8px var(--v-info-base);
    padding-left: 0;
  }

  &:hover {
    cursor: pointer;
  }

  border-radius: 5px;

  .messagerie-list--item--img {
    align-self: center;
    margin-bottom: 10px;
  }

  .messagerie-list--item--content {
    color: #707070;
    padding-right: 2em;
    width: 0;
    flex: 1;

    .messagerie-list--item--content--name {
      font-weight: bold;
      margin: 0 0 10px 0;
      color: $violet;
    }

    .fiche--objet {
      color: #616161;
      font-size: 14px;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .fiche--lastmessage {
      font-size: 14px;
      white-space: initial;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      flex: 1 1 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 3em;
      line-height: 1.5em;
    }
  }

  .etiquette--fiche {
    position: relative;
    top: -24px;
  }

  .messagerie-list--item--header {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 33%;

    .etiquette--fiche--today {
      top: -24px;
    }

    .v-image {
      margin: 10px 0;
    }

    p {
      margin: 0 !important;
    }
  }
}

.messagerie-chat {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .messagerie-chat--header {
    display: flex;

    .messagerie-chat--header--user {
      border-top-left-radius: 5px;

      display: flex;
      background-color: $violet;
      align-items: center;

      .messagerie-chat--header--user--infos {
        .name--button {
          display: flex;

          .name {
            font-weight: bold;
            margin: 0;
          }

          button {
            margin-left: 10px;
          }
        }
      }
    }

    .messagerie-chat--header--data {
      border-top-right-radius: 5px;
      display: flex;
      flex: 1;
      z-index: 10;
    }
  }

  .messagerie-chat--content {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .messagerie-chat--content--img {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 120px;
      min-width: 120px;

      .v-image {
        box-shadow: 0 0 5px 0;
      }

      p {
        font-size: 14px;
        width: 80px;
        margin-top: 15px;
        align-self: center;
        color: #c0c0c0;
        text-align: center;
      }
    }

    .messagerie-chat--content--text {
      padding: 15px;
      border-radius: 5px;

      p:last-child {
        margin-bottom: 0 !important;
      }

      .messagerie-chat--content--actions {
        opacity: 0;
        transition-delay: 1s;
      }

      &:hover {
        .messagerie-chat--content--actions {
          transition-delay: 0.3s;
          opacity: 1;
        }
      }

      img {
        display: block;
        max-width: 100%;
        max-height: 30em;
        width: auto;
        height: auto;
      }

      a {
        color: white !important;
      }
    }

    .messagerie-chat--content--left {
      margin-bottom: 1em;
      display: flex;
      align-items: flex-start;
    }

    .messagerie-chat--content--interne {
      padding: 0 10%;
      margin-bottom: 1em;
    }

    .messagerie-chat--content--right {
      align-self: flex-end;
      align-items: flex-start;

      margin-bottom: 1em;
      display: flex;
      flex-direction: row-reverse;

      * {
        word-break: break-word;
      }

      table {
        width: auto;
        table-layout: fixed;
        max-width: 100%;
      }

      table,
      thead,
      tbody,
      td,
      tr,
      th {
        //width: 0 !important;
        //max-width: 100%;
      }

      td {
        //max-width: 100px;
        //word-break: break-word !important;
        white-space: pre-wrap !important;
      }

      .messagerie-chat--content--text {
        background-color: $violet;
        color: white;
      }
    }
  }

  .messagerie-chat--footer {
    position: relative;

    .someone-is-writing {
      position: absolute;
      top: -1.2em;
      width: 100%;
      padding-top: 0.8em;
    }

    .messagerie-chat--footer--wysiwyg {
      display: grid;
      grid-template-columns: 5fr 1fr;

      //noinspection CssUnusedSymbol
      .tiptap-vuetify-editor__content {
        max-height: 250px;

        img {
          display: block;
          max-width: 800px;
          max-height: 400px;
          width: auto;
          height: auto;
        }
      }
    }

    .messagerie-chat--footer--tools {
      background: none !important;

      .v-btn {
        padding: 0;
        margin: 10px;
        border-radius: 5px;
        background-color: white;
        height: 40px !important;
        min-width: 40px !important;
        -webkit-box-shadow: 1px 1px 10px -6px black;
        -moz-box-shadow: 1px 1px 10px -6px black;
        box-shadow: 1px 1px 10px -6px black;

        .v-icon {
          color: #bebebe !important;
        }
      }
    }

    .messagerie-chat--footer--textarea {
      display: flex;
      margin-left: 15px;
      align-items: flex-end;
      margin-bottom: 20px;

      .v-icon {
        margin-right: 10px;
        font-size: 20px;
      }

      button {
        margin-left: 20px;
        height: 30px;
        margin-right: 20px;
        background-color: $violet;
        color: white;
      }

      .v-text-field__details {
        display: none;
      }

      .v-input__slot {
        margin-bottom: 0;
      }

      textarea {
        height: 120px !important;
      }
    }
  }
}

.formulaire-fiche {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .formulaire-fiche--header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .formulaire-fiche--footer {
    display: flex;
    margin-top: 10px;

    button {
      align-self: flex-end;
    }
  }
}

.filter-form {
  display: flex;
  padding: 10px;
  flex-direction: column;
}

.filter-type-form {
  display: flex;
  align-items: center;

  .v-input {
    margin: 0 !important;

    .v-messages {
      display: none;
    }
  }

  p {
    margin-bottom: 3px;
  }
}

.messagerie-chat--no-select {
  display: flex;
  justify-content: center !important;
  font-weight: bold;
  align-items: center;
  color: red;
}

.messagerie-chat--loader {
  .messagerie-chat--loader-text {
    display: flex;
    justify-content: center;

    p {
      font-weight: bold;
    }
  }
}

.dialog-gestion-users--div-suppression {
  display: flex;

  .v-input--checkbox {
    justify-self: flex-end;
  }

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tiptap-vuetify-editor__content>div {
  height: 100% !important;
}

/* ===== Esteban ===== */

.messagerie-chat--list--simple {
  height: 0;
  /* max-height: 100%; */
  /* display: contents; */
  flex: 1 1 auto;
}

.messagerie-list--item--content--url {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
}

.div-infos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  flex: 1 1 auto;
  grid-gap: 8px;
}

.div-infos-section {
  font-size: 0.8em;
}

.div-infos-section>div:first-child {
  white-space: nowrap;
}

.messagerie-chat--header--data--list {
  display: flex;
  flex-grow: 1;
  padding: 1em;
}

.div-infos-section>div:first-child {
  font-weight: 700;
  color: darkgray;
  width: 50%;
}

.messagerie-chat--content--actions {
  position: absolute;
  right: -1.5em;
  top: 0;
}

//noinspection CssUnusedSymbol
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 28px;
}

.messagerie-chat--content--text img {
  cursor: pointer;

  &:hover {
    -webkit-box-shadow: 1px 1px 7px 0 #000000;
    box-shadow: 1px 1px 7px 0 #000000;
  }
}

.dialog-img {
  height: auto;
  max-width: 100%;
  width: auto;
  max-height: 90vh;
}

.imgLightBoxToolbar {
  background: linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  top: -64px;
  transition: all 0.3s;
}

.imgLightBoxToolbar.hover {
  top: 0px;
}

.filter-active-demande_envoyee,
.filter-active-bloquant,
.filter-active-haute,
.filter-active-anomalie {
  border: 2px solid #ff5252 !important;
  font-weight: bold;
  background-color: #ff5252 !important;
  color: #fff !important;
}

.filter-active-moyenne,
.filter-active-utilisation {
  border: 2px solid #3f51b5 !important;
  font-weight: bold;
  background-color: #3f51b5 !important;
  color: #fff !important;
}

.filter-active-basse,
.filter-active-developpement {
  border: 2px solid #00bcd4 !important;
  font-weight: bold;
  background-color: #00bcd4 !important;
  color: #fff !important;
}

.filter-active-demande_cloturee,
.filter-active-demande_terminee,
.filter-active-renseignements {
  border: 2px solid #4caf50 !important;
  font-weight: bold;
  background-color: #4caf50 !important;
  color: #fff !important;
}

.filter-active-majeur,
.filter-active-mineur,
.filter-active-cosmetique {
  border: 2px solid #009688 !important;
  font-weight: bold;
  background-color: #009688 !important;
  color: #fff !important;
}

.filter-active-prise_en_compte_client {
  border: 2px solid #555555 !important;
  font-weight: bold;
  background-color: #555555 !important;
  color: #fff !important;
}

.filter-active-en_attente,
.filter-active-rappel_client,
.filter-active-prise_en_compte {
  border: 2px solid #ff9800 !important;
  font-weight: bold;
  background-color: #ff9800 !important;
  color: #fff !important;
}

.filter-active-suivi {
  border: 2px solid #F0F !important;
  font-weight: bold;
  background-color: #F0F !important;
  color: #fff !important;
}

.filter-active-doublon {
  border: 2px solid #9E9E9E !important;
  font-weight: bold;
  background-color: #9E9E9E !important;
  color: #fff !important;
}

.filter-active-appel_client {
  border: 2px solid #0F0 !important;
  font-weight: bold;
  background-color: #0F0 !important;
  color: #fff !important;
}

.v-list-gap {
  margin-right: 0.5rem;
}

.filtre-module {
  background: linear-gradient(var(--v-tertiary-base) 10%,
      var(--v-primary-base) 50%,
      var(--v-secondary-base) 90%) !important;
}

.filter-container {
  gap: 0.5rem;
}
</style>
