<template>
  <v-card elevation="2" class="messagerie-chat overflow-y-hidden">
    <div class="messagerie-chat--header elevation-2">
      <div class="messagerie-chat--header--user">
        <div class="messagerie-chat--header--user--img pl-4">
          <v-avatar size="64">
            <img
              style="width: 80px; height: auto"
              :src="
                require('@/Assets/Images/Interface/Profiles/profile-man-1.svg')
              "
              alt="Profile photo"
            />
          </v-avatar>
        </div>
        <div class="messagerie-chat--header--user--infos pa-4">
          <div class="d-flex align-center">
            <v-chip
              x-small
              color="cyan"
              label
              dark
              class="elevation-1"
              @click.stop="
                copyToClipboard(
                  makeUrl(selectedFiche),
                  $nSuccess($t('actions.copied'))
                )
              "
            >
              <a
                :href="makeUrl(selectedFiche)"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ selectedFiche.fiche.client }}
              </a>
            </v-chip>
            <a
              :href="'https://' + selectedFiche.fiche.url.text"
              target="_blank"
              rel="noopener noreferrer"
              ><p class="cyan--text ml-2 mb-0">
                {{ selectedFiche.fiche.url.text }}
              </p></a
            >
          </div>
          <div class="name--button">
            <span class="name white--text">
              {{ usernameUser(selectedFiche.fiche.userCreate) }}
            </span>
            <v-menu
              offset-y
              v-if="getUserListFiche.length != 0 && this.main_hotline_base"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small v-bind="attrs" v-on="on">
                  + {{ getUserListFiche.length }}
                  {{ $t('messagerie.chat.other') }}
                </v-btn>
              </template>

              <v-list disabled avatar>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(item, i) in getUserListFiche" :key="i">
                    <v-list-item-avatar>
                      <v-img
                        :src="
                          require('@/Assets/Images/Interface/Profiles/profile-man-1.svg')
                        "
                        alt="Profile photo"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ usernameUser(item) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
          <span class="white--text">
            {{ timestampFormat(lastMessage(selectedFiche.fiche).time, 'LLL') }}
          </span>
        </div>
      </div>
      <div class="messagerie-chat--header--data">
        <div class="messagerie-chat--header--data--list">
          <div class="div-infos">
            <div class="div-infos-section">
              <div>{{ $t('messagerie.chat.object') }}</div>
              <div>{{ selectedFiche.fiche.objet }}</div>
            </div>
            <div class="div-infos-section">
              <div>{{ $t('messagerie.chat.open') }}</div>
              <div>{{ formatDate(selectedFiche.createdAt) }}</div>
            </div>
            <div
              v-if="
                selectedFiche.fiche.date_previsionnelle && isGtaOrCompteur()
              "
              class="div-infos-section"
              style="grid-row: 2; grid-column: 2;"
            >
              <div>{{ $t('messagerie.date_previsionnelle') }}</div>

              <v-menu offset-y bottom >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="d-flex align-center">
                    {{ getExceptedDate }}
                    <!-- icon d'edition -->
                    <v-tooltip top v-if="user.superAdministrator">
                      <template v-slot:activator="{ on }">
                        <v-btn icon x-small v-on="on">
                          <v-icon class="ml-2 mb-1">mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>editer la date</span>
                    </v-tooltip>
                    <!-- Icon de corbeille -->
                    <v-tooltip top  v-if="user.superAdministrator">
                      <template v-slot:activator="{ on }">
                        <v-btn icon x-small v-on="on" @click.stop="deleteExceptedDate">
                          <v-icon class="ml-2 mb-1">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Supprimer la date</span>
                    </v-tooltip>
                  </div>
                </template>
                <v-list v-if="user.superAdministrator">
                  <v-list-item>
                    <v-list-item-title>
                      <v-container>
                        <v-row>
                          <v-col>
                            <span>Selectionner une date</span>
                            <v-menu v-if="user.superAdministrator">
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  outlined
                                  dense
                                  hide-details
                                  @input="handleDateSelection"
                                  v-model="updateExceptedDate"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="updateExceptedDate"
                                :locale="$i18n.locale"
                              ></v-date-picker>
                            </v-menu>
                            <!-- bouton save -->
                            <v-btn v-if="user.superAdministrator"
                              small
                              class="mt-1"
                              color="success"
                              @click="sendExceptedDate()"
                            >
                              {{ $t('actions.save') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="div-infos-section">
              <div>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <div
                      style="cursor: pointer;"
                      @click="
                        copyToClipboard(
                          makeUrl(selectedFiche),
                          $nSuccess($t('actions.copied'))
                        )
                      "
                      v-on="on"
                    >
                      {{ $t('messagerie.chat.ticket') }}
                    </div>
                  </template>
                  <span>{{ $t('messagerie.copie_lien_ticket') }}</span>
                </v-tooltip>
              </div>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      text
                      class="pa-0 ma-0"
                      min-width="0"
                      height="0"
                      small
                      @click="
                        copyToClipboard(
                          selectedFiche.fiche.ticket,
                          $nSuccess($t('actions.copied'))
                        )
                      "
                      v-on="on"
                    >
                      {{ selectedFiche.fiche.ticket }}
                    </v-btn>
                  </template>
                  <span>{{ $t('messagerie.copie_libelle') }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="div-infos-section" v-if="selectedFiche.fiche.phone">
              <div>{{ $t('messagerie.chat.phone') }}</div>
              <div>
                {{ selectedFiche.fiche.phone }}
                <img
                  v-if="selectedFiche.fiche.phoneCountry"
                  class="ml-2"
                  :src="
                    'https://flagcdn.com/20x15/' +
                      selectedFiche.fiche.phoneCountry.toLowerCase() +
                      '.png'
                  "
                  width="20"
                  height="15"
                />
              </div>
            </div>
            <div class="div-infos-section">
              <div>{{ $t('messagerie.chat.module') }}</div>
              <div>
                {{
                  getModuleName(
                    selectedFiche.fiche.module,
                    selectedFiche.fiche.sous_module,
                    modules
                  ).module.name
                }}
              </div>
            </div>
            <div
              class="div-infos-section"
              v-if="selectedFiche.fiche.sous_module !== null"
            >
              <div>{{ $t('messagerie.chat.sous_module') }}</div>
              <div>
                {{
                  getModuleName(
                    selectedFiche.fiche.module,
                    selectedFiche.fiche.sous_module,
                    modules
                  ).sous_module.name
                }}
              </div>
            </div>
            <div class="div-infos-section">
              <div>{{ $t('messagerie.chat.type') }}</div>
              <v-chip
                small
                :color="
                  ColorsUtils.getTypeColor(
                    selectedFiche.fiche.type_demande.value.id
                  )
                "
                label
                dark
                class="elevation-1 mr-4 chip-truncate"
              >
                {{
                  $t(
                    'messagerie.parametrages.' +
                      selectedFiche.fiche.type_demande.text
                  )
                }}
              </v-chip>
            </div>
            <div class="div-infos-section">
              <div>{{ $t('messagerie.chat.etat') }}</div>
              <v-chip
                small
                :color="getColorEtat(selectedFiche.fiche.etat.value.id)"
                label
                dark
                class="font-weight-bold elevation-1 etiquette--fiche"
                >{{
                  $t('messagerie.parametrages.' + selectedFiche.fiche.etat.text)
                }}
              </v-chip>
            </div>
            <!-- si il est call center -->
            <div v-if="main_hotline_base" class="div-infos-section">
              <div>{{ selectedFiche.fiche.tags.length > 1 ? 'Tags' : 'Tag' }}</div>
              <div
                v-for="(fiche, index) in selectedFiche.fiche.tags"
                :key="index"
              >
                {{ fiche.tag.name }}
              </div>
            </div>

            <div v-if="main_hotline_base && selectedFiche.fiche.suivi" class="div-infos-section">
                <div>Suivi</div>
                <div >
                  <v-icon color="#F0F">mdi-bookmark-check</v-icon>
                </div>
            </div>
          </div>

          <v-progress-circular
            v-if="showLoader"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <!-- Menu fiche -->
        <div class="messagerie-chat--header--data--button ma-2">
          <menu-fiche
            :mini="false"
            :fiche="selectedFiche"
            :rightsUser="rightsUser"
            :users="users"
            :impacts="impacts"
            :ordre_priorites="ordre_priorites"
            :etats="etats"
            :type_demandes="type_demandes"
            :tagsPlaneteOnline="tagsPlaneteOnline"
            :user="user"
          />
          <v-btn
            color="red"
            v-if="detailledView"
            dark
            @click="$emit('close-chat')"
            >{{ $t('actions.close') }}</v-btn
          >
        </div>
      </div>
    </div>
    <!-- scroll messages -->
    <vue-scroll
      class="pr-5 vue-scroll-list-messages"
      :ops="optionsScrollChat"
      ref="scrollChat"
    >
      <div class="messagerie-chat--content px-8">
        <div v-for="(msg, index) in selectedFiche.fiche.messages" :key="msg.id">
          <div v-if="msg.archived_msg == undefined || msg.archived_msg == false">
            <div
            v-if="
                msg.type == 0 ||
                (msg.type == 1 &&
                rightsUser != undefined &&
                Object.keys(rightsUser).length > 0) 
                "
              :class="{
                'messagerie-chat--content--right':
                msg.idUser == userId && msg.type == 0,
                'messagerie-chat--content--left':
                msg.idUser != userId && msg.type == 0,
                'messagerie-chat--content--interne': msg.type == 1,
              }"
            >
              <div
                class="messagerie-chat--content--img"
                :class="{
                  'ml-8': msg.idUser == userId && msg.type == 0,
                  'mr-8': msg.idUser != userId && msg.type == 0,
                }"
                v-if="msg.type == 0"
              >
                <v-avatar
                  v-if="messageBeforeSameUser(index)"
                  size="24"
                  class="mb-2"
                >
                  <img
                    style="width: 80px; height: auto"
                    :src="
                      require('@/Assets/Images/Interface/Profiles/profile-man-1.svg')
                    "
                    alt="Profile photo"
                  />
                </v-avatar>
                <span
                  v-if="!checkMessageToday(msg.time)"
                  class="text-no-wrap grey--text"
                >
                  {{ timestampFormat(msg.time, 'LL') }}
                </span>
                <span class="text-no-wrap grey--text">{{
                  timestampFormat(msg.time, 'HH[h]mm')
                }}</span>
              </div>
              <div class="messagerie-chat--content--text" v-if="msg.type == 1">
                <p class="text-center">
                  {{ $t('messagerie.text.internally_message') }}
                </p>
              </div>
              <!-- renseigner la date du message -->
              <div v-if="msg.type == 1" class="text-no-wrap grey--text">
                {{ timestampFormat(msg.time, 'LL') }}
                {{ timestampFormat(msg.time, 'HH[h]mm') }}
              </div>
              <v-card
                style="display: inline-block;"
                class="messagerie-chat--content--text"
                :class="{
                  'teal white--text': msg.type == 1,
                  'grey lighten-3':
                    !$vuetify.theme.dark && msg.idUser != userId && msg.type == 0,
                  backgroundAppColor:
                    $vuetify.theme.dark && msg.idUser != userId && msg.type == 0,
                }"
                elevation="2"
              >
                <div class="messagerie-chat--content--actions d-flex flex-column justify-space-between" style="height: 100%;" >
                  <v-btn 
                    :class="{
                      'ml-2': msg.idUser == userId && msg.type == 0 && user.superAdministrator && msg.files && msg.files.length > 0,
                    }"
                    icon
                    class="ml-2"
                    x-small
                    dark
                    :title="$t('actions.update')"
                    v-if="rightUpdate(msg)  && usernameMsgCreate(msg.user) == user.firstname + ' ' + user.lastname"
                    @click="messageInUpdating(msg)"
                  >
                    <v-icon color="primary">mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="msg.idUser == userId && msg.type == 0 && user.superAdministrator && msg.files && msg.files.length > 0"
                    icon
                    small
                    :title="msg.files.length > 1 ? 'Supprimer les pièces jointes' : 'Supprimer la pièce jointe'"
                    @click="deleteFile(msg)"
                  >
                    <v-icon small color="red">mdi-delete</v-icon>
                  </v-btn>
                  <!-- pouvoir archiver msg si superadmin et si je suis l'auteur du msg -->
                  <v-btn
                    v-if="msg.idUser == userId && user.superAdministrator && usernameMsgCreate(msg.user) == user.firstname + ' ' + user.lastname && !msg.files"
                    icon
                    small
                    title="Archiver le message"
                    @click="archiveMessage(msg)"
                  >
                    <v-icon small color="error">mdi-archive-off-outline</v-icon>
                  </v-btn>
                </div>
                <h3 v-if="messageBeforeSameUser(index) && userId != msg.idUser">
                  {{ usernameUser(msg.user) }}
                </h3>
                <p v-if="msg.updated && msg.idUser == userId" class="caption">
                  {{ $t('messagerie.text.message_updated') }}
                </p>
                <p v-if="msg.updated && msg.idUser != userId" class="caption">
                  {{ $t('messagerie.text.message_updated_not_me') }}
                </p>
                <template v-if="msg.content != '' && msg.content != '<p></p>'">
                  <p v-html="msg.content">
                    {{ msg.content }}
                  </p>
                </template>

                <div v-if="msg.files != null">
                  <v-divider
                    v-if="msg.content != '' && msg.content != '<p></p>'"
                  ></v-divider>
                  <h5>
                    <v-icon :dark="msg.idUser == userId && msg.type == 0"
                      >mdi-paperclipttt</v-icon
                    >
                    <p v-if="msg.files && msg.files.length !== 0">{{ $t('messagerie.pj.title') }}</p> 
                    <p v-else-if="msg.files && msg.files.length == 0 && user.superAdministrator" >{{ $t('messagerie.pj.deleted_pj') }}</p>
                  </h5>
                  <chip-attachment
                    v-for="file in msg.files"
                    :key="file"
                    :file="getOriginalFile(file)"
                    @download="downloadFile(file)"
                    small
                    class="ma-2 mb-0"
                  ></chip-attachment>
                </div>
                
              </v-card>
            </div>
          </div>
        </div>
        <div v-if="someoneTyping()" class="messagerie-chat--content--left">
          <v-card
            class="messagerie-chat--content--text pr-8 py-2"
            :class="{
              backgroundAppColor: $vuetify.theme.dark,
              'grey lighten-3': !$vuetify.theme.dark,
            }"
          >
            {{ $t('messagerie.chat.someone_is_writing') }}
            <text-loader class="ml-1" size="4"></text-loader>
          </v-card>
        </div>
      </div>
    </vue-scroll>
    <div
      class="messagerie-chat--footer"
      :class="{
        'grey lighten-3': !$vuetify.theme.dark,
        'grey darken-4': $vuetify.theme.dark,
      }"
    >
      <div class="messagerie-chat--footer--wysiwyg">
        <div
          class="pa-4"
          :class="{
            '': isFicheDone,
            resize: !isFicheDone,
          }"
        >
          <template v-if="!messageInUpdate.inUpdate">
            <editor
              :key="keyLangCheat + keyRefreshCheat"
              v-model="content"
              @fail-file="keyRefreshCheat = $uuid.v4()"
              :placeholder="$t('messagerie.text.placeholder')"
              @select-attachment="dialogPieceJointe = true"
              :disabled="isFicheDone"
            ></editor>
            <v-divider v-if="filesUploaded.length > 0" class="my-2"></v-divider>
            <!-- Affichage des fichiers sous l'étiteur tiptap -->
            <h5 v-if="filesUploaded.length > 0">
              {{ $t('messagerie.pj.title') }}
            </h5>
            <template v-if="filesUploaded.length <= 4">
              <chip-attachment
                v-for="file in filesUploaded"
                :key="file.new_name"
                :file="file"
                @download="downloadFile(file, true)"
                small
                class="ma-2 mb-0"
              ></chip-attachment>
            </template>
            <template v-else>
              <chip-attachment
                v-for="file in filesUploaded.slice(0, 4)"
                :key="file.new_name"
                :file="file"
                @download="downloadFile(file, true)"
                small
                class="ma-2 mb-0"
              ></chip-attachment>
              <v-chip
                v-if="filesUploaded.length > 4"
                small
                class="ma-2 mb-0"
                @click="dialogPieceJointe = true"
                >+ {{ filesUploaded.length - 4 }}
              </v-chip>
            </template>
          </template>
          <template v-else>
            <editor
              :key="keyLangCheat + keyRefreshCheat"
              @fail-file="keyRefreshCheat = $uuid.v4()"
              :placeholder="$t('messagerie.text.placeholder')"
              v-model="messageInUpdate.msg.content"
            ></editor>
          </template>
        </div>
        <div class="d-flex flex-column align-center justify-center">
          <v-btn
            color="teal"
            class="mb-4 btn-send-internally"
            @click="addMessageFiche(1)"
            :dark="
              !(
                (content == '' || content == '<p></p>') &&
                filesUploaded.length == 0
              )
            "
            :disabled="
              (content == '' || content == '<p></p>') &&
                filesUploaded.length == 0
            "
            v-if="main_hotline_base && !loadingSendMessage"
          >
            <v-icon class="icon-rotate" small left>mdi-send</v-icon>
            {{ $t('messagerie.text.internally_send') }}
          </v-btn>
          <v-btn
            @click="addMessageFiche(0)"
            color="primary"
            :disabled="
              (content == '' || content == '<p></p>') &&
                filesUploaded.length == 0
            "
            v-if="
              (rightsUser.answerClient || !main_hotline_base) &&
                !loadingSendMessage &&
                !messageInUpdate.inUpdate
            "
          >
            <v-icon class="icon-rotate" left small>mdi-send</v-icon>
            {{ $t('messagerie.text.send') }}
          </v-btn>
          <v-btn
            @click="undoUpdateMessage()"
            class="mb-1"
            v-if="messageInUpdate.inUpdate && !loadingSendMessage"
          >
            <v-icon left small>mdi-close-thick</v-icon>
            {{ $t('actions.cancel') }}
          </v-btn>
          <v-btn
            @click="updateMessage()"
            v-if="messageInUpdate.inUpdate && !loadingSendMessage"
            :disabled="
              messageInUpdate.msg.content == '' ||
                messageInUpdate.msg.content == '<p></p>'
            "
          >
            <v-icon left small>mdi-send</v-icon>
            {{ $t('actions.update') }}
          </v-btn>
          <v-progress-circular
            v-if="loadingSendMessage"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </div>
    <!-- Validation envoi message client -->
    <v-dialog v-model="dialogValidateMsg" max-width="480">
      <v-card>
        <v-card-title
          >{{ $t('messagerie.chat.title_send_client') }}
        </v-card-title>
        <v-card-text>
          <p>
            <v-icon color="warning" class="mr-3">mdi-alert</v-icon>
            {{ $t('messagerie.chat.confirm_send_client') }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              dialogValidateMsg = false;
              loadingSendMessage = false;
            "
          >
            {{ $t('actions.cancel') }}
          </v-btn>
          <v-btn color="success" @click="validateMsg()">
            {{ $t('messagerie.text.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-attachment
      v-model="filesUploaded"
      @addFile="addFileFiche"
      @remove-file-fiche="removeFileFiche"
      :show.sync="dialogPieceJointe"
    ></dialog-attachment>
    <!-- dialog d arhcivage de msg -->
    <v-dialog v-model="dialogArchive" max-width="480">
      <v-card>
        <v-card-title
          >Confirmation d'archivage du message
        </v-card-title>
        <v-card-text>
          <p>
            <v-icon color="warning" class="mr-3">mdi-alert</v-icon>
            Êtes-vous sûr(e) de vouloir archiver ce message ?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              dialogArchive = false;
              authArchive = false;
            "
          >
            {{ $t('actions.cancel') }}
          </v-btn>
          <v-btn color="warning" @click="authArchive = true">
            archiver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import HotlineBus from '@/Components/Views/Hotline/HotlineBus';
import MessagerieService from '@/Services/MessagerieService';
import ColorsUtils from '@/Utils/MessagerieColors';
import Parsing from '@/Utils/Parsing';
import moment from 'moment';
import Vuex, { mapGetters } from 'vuex';

export default {
  name: 'Chat',
  components: {
    Editor: () => import('@/Components/Commons/Inputs/Editor/Editor'),
    TextLoader: () => import('@/Components/Commons/UiElements/TextLoader'),
    MenuFiche: () => import('@/Components/Views/Hotline/Ticket/TicketMenu'),
    DialogAttachment: () =>
      import('@/Components/Commons/Inputs/Attachment/DialogAttachment'),
    ChipAttachment: () =>
      import('@/Components/Commons/Inputs/Attachment/ChipAttachment'),
  },
  props: {
    selectedFiche: Object,
    main_hotline_base: Boolean,
    modules: Array,
    user: Object,
    users: Array,
    rightsUser: Object,
    impacts: Array,
    ordre_priorites: Array,
    etats: Array,
    type_demandes: Array,
    tagsPlaneteOnline: Array,
    optionsScrollChat: Object,
    userId: Number,
    messagesInWritings: Array,
    keyLangCheat: String,
    files: Array,
    socket: Object,
    informations: Object,
    detailledView: Boolean,
  },
  data() {
    return {
      ColorsUtils,
      keyRefreshCheat: this.$uuid.v4(),
      loadingSendMessage: false,
      filesUploaded: [],
      content: ``,
      contentNewFiche: '',
      messageInUpdate: {
        msg: {},
        inUpdate: false,
      },
      dialogValidateMsg: false,
      dialogPieceJointe: false,
      selectedOption: null,
      options: ['1', '2'],
      // ---------- mohamed ---------- //
      drafts: {
        draftContent: '',
        ficheId: null,
      },
      typingTimeout: null,
      isDraft: false,
      showLoader: false,
      updateExceptedDate: null,
      dialogArchive: false,
      authArchive: false,
    };
  },
  watch: {
    content() {
      this.pingServerMessageTap();
      this.$emit('change-content', this.content);
      this.saveDraft();
      // this.loadDraft(this.selectedFiche.fiche.id);
    },
    // si isAuthArchive est à true alors on archive le message
    authArchive() {
      if (this.authArchive) {
        this.archiveMessage(this.msgToArchive);
        this.dialogArchive = false;
      }
    },
  },
  computed: {
    ...mapGetters(['getBase']),
    ...Vuex.mapGetters(['getMessagerieData']),
    ...Vuex.mapMutations(['setMsgToArchive']),
    ...Vuex.mapState(['msgToArchive']),
    isFicheDone() {
      return this.$store.state.isStatusFicheDone;
    },
    getExceptedDate() {
      const datePrev = this.selectedFiche.fiche.date_previsionnelle;
      moment.locale('fr');
      const formattedDate = moment(datePrev).format('D MMMM, YYYY');
      return formattedDate;
    },
    /**
     * Récupère la liste des users d'une fiche
     */
    getUserListFiche() {
      return this.selectedFiche.fiche.users
        .filter((u) => {
          if (u.id != this.user.id) {
            return u;
          }
          if (u.id != this.selectedFiche.fiche.userCreate.id) {
            return u;
          }
        })
        .sort(this.compareUsers);
    },
    // retourne lauteur du message
    usernameMsgCreate() {
      return (user) => {
        if (user) {
          return user.firstname + ' ' + user.lastname;
        }
      };
    },
  },
  methods: {
    // method pour verifier si cest une fiche du module GTA ou une fiche avec le tag Compteur
    isGtaOrCompteur() {
      if (
        this.selectedFiche.fiche.module.name == 'GTA' ||
        this.selectedFiche.fiche.tags.find((tag) => tag.tag.name == 'Compteur')
      ) {
        return true;
      } else {
        return false;
      }
    },
    // update de la date previsionnelle
    async handleDateSelection(date) {
      this.updateExceptedDate = date;
    },
    async sendExceptedDate() {
      const date = moment(this.updateExceptedDate).format('YYYY-MM-DD');
      const res = await MessagerieService.updateExceptedDate({
        fiche: this.selectedFiche.fiche.id,
        exceptedDate: date,
      });
      if (res.status == 200) {
        this.$nSuccess(this.$t('Date prévisionnelle modifiée avec succès'));
        this.selectedFiche.fiche.date_previsionnelle.exceptedDate = date;
      }
    },
    async deleteExceptedDate() {
      await MessagerieService.deleteDatePrevisionnelle({
        fiche: this.selectedFiche.fiche.id,
      });
    },
    // --------------------via le localStorage -------------------- //
    saveDraft() {
      const ficheId = this.selectedFiche.fiche.id;
      // verifier si content est vide
      if (this.content.trim().replace(/<[^>]*>/g, '').length == 0) {
        this.resetDraft();
      }
      // Vérifiez si le contenu du message n'est pas vide avant de sauvegarder le brouillon
      if (this.content !== '' && this.content !== '<p></p>') {
        // Sauvegarde le brouillon dans le store
        this.$store.commit('addDraft', {
          ficheId: ficheId,
          draft: this.content,
        });
        // Sauvegarde également le brouillon dans le LocalStorage
        localStorage.setItem(`draft_${ficheId}`, this.content);

        // Si un délai est déjà en cours, réinitialisez-le
        if (this.typingTimeout) {
          clearTimeout(this.typingTimeout);
        }
        // 1 seconde (1000 millisecondes) avant d'envoyer la notification
        this.typingTimeout = setTimeout(() => {
          // si l'utilisateur a supp le brouillon ne pas afficher la notification
          if (
            !this.isDraft &&
            this.content.trim().replace(/<[^>]*>/g, '').length != 0
          ) {
            // traduire le message
            const message = this.$t('notifications.save_draft');
            // Affiche la notification
            this.$nInfo(message);
            // this.showLoader = true;
            // setTimeout(() => {
            //   // Fin de la sauvegarde
            //   this.showLoader = false; // Masque le loader
            // }, 1000);
          } else {
            this.isDraft = false;
          }
          this.typingTimeout = null;
        }, 2000);
      }
    },

    // Méthode pour charger le brouillon associé à la fiche depuis le LocalStorage dans l'éditeur
    loadDraft(ficheId) {
      const draft = this.$store.state.drafts.find(
        (draft) => draft.ficheId === ficheId
      );
      // Si le brouillon est trouvé dans le store
      if (draft && draft.draft) {
        this.content = draft.draft;
        this.$emit('draft-loaded', this.content);
        this.isDraft = true;
        // traduire le message
        const message = this.$t('notifications.load_draft');
        // Affiche la notification
        this.$nInfo(message);
      } else {
        // Sinon, essayez de récupérer le brouillon depuis le LocalStorage
        const localStorageDraft = localStorage.getItem(`draft_${ficheId}`);
        if (localStorageDraft) {
          this.content = localStorageDraft;
          this.$emit('draft-loaded', this.content);
          if (this.content !== '' && this.content !== '<p></p>') {
            this.isDraft = true;
            // traduire le message
            const message = this.$t('notifications.load_draft');
            // Affiche la notification
            this.$nInfo(message);
          }
        }
      }
    },
    resetDraft() {
      this.content = '';
      this.$store.commit('removeDraft', this.selectedFiche.fiche.id);
      // Supprime également le brouillon associé du LocalStorage
      localStorage.removeItem(`draft_${this.selectedFiche.fiche.id}`);
    },
    // supprimer une piece jointe
    async deleteFile(msg) {
      const res = await MessagerieService.removeFile({
        fiche: this.selectedFiche.fiche.id,
        message: msg,
      });
      if (res.status == 200) {
        this.$nSuccess(this.$t('Piece jointe supprimée avec succès'));
      }
      // mettre a jour la liste des fichiers
      this.selectedFiche.fiche.messages = this.selectedFiche.fiche.messages.map(
        (message) => {
          if (message.id == msg.id) {
            message.files = null;
          }
          return message;
        }
      );
    },
    // archiver un message
    async archiveMessage(msg) {
      this.$store.commit('setMsgToArchive', msg);
      this.dialogArchive = true;
      if (this.authArchive) { 
        // activer le loader
        this.showLoader = true;
        // si dans msg il y a deja arichived_msg à true alors on le met à false
        msg.archived_msg && msg.archived_msg == true ? msg.archived_msg = false : msg.archived_msg = true;
        const res = await MessagerieService.archiveMessage({
          fiche: this.selectedFiche.fiche.id,
          message: msg,
          archive: msg.archived_msg,
        });
        // on met à jour la liste des messages
        this.selectedFiche.fiche.messages = this.selectedFiche.fiche.messages.map(
          (message) => {
            if (message.id == msg.id) {
              return msg;
            }
            return message;
          }
        );
        if (res.status == 200) {
          this.authArchive = false;
          this.showLoader = false;
          this.$nSuccess(this.$t('Message archivé avec succès'));
        }
      } 
    },
    makeUrl(fiche = null) {
      if (fiche) {
        return (
          'https://' +
          this.getDns() +
          '/planete-online/app/hotline?ticket=' +
          fiche._id
        );
      }
    },
    rightUpdate(msg) {
      if (this.isMasterBase()) {
        return true;
      } else {
        //Si c'est bien le dernier message
        if (
          this.selectedFiche.fiche.messages[
            this.selectedFiche.fiche.messages.length - 1
          ].id == msg.id &&
          msg.idUser == this.userId
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    removeFileFiche(fileRemoved) {
      if (this.selectedFiche != null) {
        this.selectedFiche.fiche.files = this.selectedFiche.fiche.files.filter(
          (f) => f.new_name != fileRemoved.new_name
        );
      }
    },
    /**
     * S'active lors de la saisie dans le tiptap
     */
    pingServerMessageTap() {
      let user = Object.assign({}, this.user);
      delete user.photo;
      let base = this.getBase();
      if (this.content == '' || this.content == '<p></p>') {
        this.socket.emit('message-in-writing', {
          type: 0,
          fiche: this.selectedFiche.fiche.id,
          user,
          base,
        });
      } else {
        clearTimeout(this.timeoutMessaging);

        this.socket.emit('message-in-writing', {
          type: 1,
          fiche: this.selectedFiche.fiche.id,
          user,
          base,
        });
        let that = this;
        this.timeoutMessaging = setTimeout(function() {
          that.socket.emit('message-in-writing', {
            type: 0,
            fiche: that.selectedFiche.fiche.id,
            user,
            base,
          });
        }, 3000);
      }
    },
    addFileFiche(fileAdded) {
      if (this.selectedFiche != null) {
        if (typeof this.selectedFiche.fiche.files == 'undefined') {
          this.selectedFiche.fiche.files = [];
        }
        this.selectedFiche.fiche.files.push(fileAdded);
      }
    },
    /**
     * Permet de download un fichier lors du clic sur sa card
     * @var file le nom du fichier
     */
    downloadFile(file, preload = false) {
      let object = null;
      if (preload) {
        object = file;
      } else {
        object = this.selectedFiche.fiche.files.find((f) => f.new_name == file);
      }

      MessagerieService.downloadFile({
        preload,
        file: object,
        fiche: this.selectedFiche.fiche.id,
      }).then((res) => {
        let fileURL = window.URL.createObjectURL(
          new Blob([res], { type: object.type })
        );
        let fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', object.name);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    getOriginalFile(file) {
      if (this.selectedFiche.fiche.files != undefined) {
        return this.selectedFiche.fiche.files.find((f) => f.new_name == file);
      } else {
        return null;
      }
    },
    /**
     * Message en cours de modification
     */
    async messageInUpdating(msg) {
      this.messageInUpdate.msg = Object.assign({}, msg);
      let wrapper = document.createElement('div');
      wrapper.innerHTML = this.messageInUpdate.msg.content;
      let imageToRemove = [];
      await this.transformImageMessage(
        wrapper,
        'before_update',
        null,
        imageToRemove
      );

      this.messageInUpdate.inUpdate = true;
      this.messageInUpdate.imageToRemove = imageToRemove;
      this.messageInUpdate.msg.content = wrapper.innerHTML;
    },
    /**
     * Permet d'ajouter le message à la fiche en cours de selection
     */
    async addMessageFiche(type) {
      let $this = this;
      let sendWithoutCheck = false;
      if (this.rightsUser.hotline) {
        sendWithoutCheck = true;
      }
      if (!this.main_hotline_base) {
        sendWithoutCheck = true;
      }
      if (type == 1) {
        sendWithoutCheck = true;
      }
      let wrapper = document.createElement('div');
      wrapper.innerHTML = this.content;
      let base64imgs = [];
      await this.transformImageMessage(wrapper, 'add', base64imgs);
      this.loadingSendMessage = true;
      let user = this.user;
      user = await this.removeDimensionInutileUser(user);
      let message = {
        id: 'msg-' + this.$uuid.v4(),
        content: Parsing.urlify(this.content),
        time: moment().unix(),
        user,
        idUser: this.userId,
        type,
        files: this.filesUploaded.map((f) => f.new_name),
      };

      let formData = new FormData();
      formData.append('base_hotline', this.main_hotline_base);
      formData.append('fiche', this.selectedFiche.fiche.id);
      formData.append('message', JSON.stringify(message));
      formData.append('files', JSON.stringify(this.filesUploaded));
      let files = [...this.selectedFiche.fiche.files];
      base64imgs.forEach((i) => {
        formData.append('images', i);
      });
      if (sendWithoutCheck) {
        await MessagerieService.addMessageFiche(formData)
          .then(() => {
            $this.content = ``;
            // this.keyLangCheat = this.$uuid.v4();
            $this.filesUploaded = [];
            $this.selectedFiche.fiche.files = files;
            HotlineBus.$emit('Chat-reset-attachment');
          })
          .catch((e) => {
            this.$nError(e.message);
          })
          .finally(() => {
            $this.loadingSendMessage = false;
            $this.resetDraft();
          });
      } else {
        this.formDataValidateMessage = formData;
        this.dialogValidateMsg = true;
      }
    },
    checkMessageToday(time) {
      let ymdNow = moment().format('MM-DD-YYYY');
      let ymdMsg = moment(time * 1000).format('MM-DD-YYYY');
      if (ymdNow == ymdMsg) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Permet de valider le message vers un client
     */
    async validateMsg() {
      this.loadingSendMessage = true;
      await MessagerieService.addMessageFiche(this.formDataValidateMessage)
        .then((res) => {
          this.content = ``;
          this.formDataValidateMessage = null;
        })
        .catch((err) => {
          this.$nError('Error when sending message');
        })
        .finally(() => {
          this.loadingSendMessage = false;
          this.resetDraft();
        });
      this.dialogValidateMsg = false;
    },
    /**
     * Vérifie qu'un message précède ou non le meme utilisateur
     * @var index l'index du message
     */
    messageBeforeSameUser(index) {
      if (index == 0) {
        return true;
      }
      if (this.selectedFiche.fiche.messages[index].idUser == -1) {
        return true;
      }
      if (
        this.selectedFiche.fiche.messages[index].type !=
        this.selectedFiche.fiche.messages[index - 1].type
      ) {
        return true;
      }
      if (
        this.selectedFiche.fiche.messages[index].idUser !=
        this.selectedFiche.fiche.messages[index - 1].idUser
      ) {
        return true;
      }
    },
    /**
     * Permet de savoir quel dernier message afficher dans la liste de gauche
     * @var fiche la fiche
     */
    lastMessage(fiche) {
      if (
        typeof fiche.last_message_interne != 'undefined' &&
        this.user.superAdministrator
      ) {
        typeof fiche.last_message == 'undefined'
          ? (fiche.last_message = fiche.messages[0])
          : fiche.last_message;
        return fiche.last_message_interne.time > fiche.last_message.time
          ? fiche.last_message_interne
          : fiche.last_message;
      } else {
        return fiche.last_message;
      }
    },
    /**
     * Transforme les base 64 en url ou inverse
     */
    async transformImageMessage(
      wrapper,
      type,
      base64imgs = null,
      imageToRemove = null
    ) {
      if (type == 'update' || type == 'add') {
        await Promise.all(
          Object.entries(wrapper.getElementsByTagName('img')).map(async (i) => {
            let mime = '';
            let ext = '';
            if (i[1].src.includes('image/jpeg')) {
              mime = 'image/jpeg';
              ext = '.jpg';
            }
            if (i[1].src.includes('image/png')) {
              mime = 'image/png';
              ext = '.png';
            }
            let new_name = 'img-' + this.$uuid.v4() + ext;

            let file = await this.dataUrlToFile(i[1].src, new_name, mime);
            base64imgs.push(file);

            if (type == 'update') {
              this.messageInUpdate.msg.content = this.messageInUpdate.msg.content.replace(
                i[1].src,
                process.env.VUE_APP_BASEURL +
                  '/messagerie/img-content/' +
                  new_name
              );
            }
            if (type == 'add') {
              this.content = this.content.replace(
                i[1].src,
                process.env.VUE_APP_BASEURL +
                  '/messagerie/img-content/' +
                  new_name
              );
            }

            // i[1].src = "img-" + this.$uuid.v4();
          })
        );
      }
      if (type == 'before_update') {
        await Promise.all(
          Object.entries(wrapper.getElementsByTagName('img')).map(async (i) => {
            let nom_fichier = i[1].src.split('/');
            nom_fichier = nom_fichier[nom_fichier.length - 1];
            imageToRemove.push(nom_fichier);
            let base64 = await this.getBase64Image(i[1].src);
            this.messageInUpdate.msg.content = this.messageInUpdate.msg.content.replace(
              i[1].src,
              base64
            );
            i[1].src = base64;
          })
        );
      }
    },
    async dataUrlToFile(dataUrl, fileName, mime) {
      const res = await fetch(dataUrl);
      const blob = await res.blob();
      return new File([blob], fileName, { type: mime });
    },
    /**
     * Mets à jour le message en cours de modification
     */
    async updateMessage() {
      let wrapper = document.createElement('div');
      wrapper.innerHTML = this.messageInUpdate.msg.content;
      let base64imgs = [];
      await this.transformImageMessage(wrapper, 'update', base64imgs);

      this.messageInUpdate.msg.messageUpdated = true;
      let formData = new FormData();
      formData.append('fiche', this.selectedFiche.fiche.id);
      formData.append('msg', JSON.stringify(this.messageInUpdate.msg));
      formData.append(
        'imgToRemove',
        JSON.stringify(this.messageInUpdate.imageToRemove)
      );
      base64imgs.forEach((i) => {
        formData.append('images', i);
      });
      await MessagerieService.updateMessageFiche(formData)
        .then(() => {
          this.messageInUpdate.msg = {};
          this.messageInUpdate.inUpdate = false;
        })
        .finally(() => {
          this.loadingSendMessage = false;
        });
    },
    /**
     * Annulation de la modification du message
     */
    undoUpdateMessage() {
      delete this.messageInUpdate.msg;
      this.messageInUpdate.msg = {};
      this.messageInUpdate.inUpdate = false;
    },
    /**
     * Permet de savoir si quelqu'un écrit
     */
    someoneTyping() {
      let $this = this;
      return this.messagesInWritings.filter((m) => {
        // Retourne true si quelqu'un écrit sur la fiche et que ce quelqu'un n'est pas l'utilisateur en cours
        return (
          m.fiche == this.selectedFiche.fiche.id &&
          m.user.id != this.informations.id
        );
      }).length;
    },
    async getBase64Image(url) {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    },
    /**
     * Concatène le nom de l'user
     * @var userId Id de l'user
     */
    usernameUser(user) {
      return user.firstname + ' ' + user.lastname.toUpperCase();
    },
  },
  mounted() {
    // Écouter l'événement personnalisé "load-draft" du composant parent
    this.$parent.$on('load-draft', this.loadDraft);
    HotlineBus.$on('Chat-reset-content', () => (this.content = ``));
    this._keyListener = function(e) {
      if (e.key === 'Escape') {
        e.preventDefault();
        this.$emit('close-chat');
      }
    };
    document.addEventListener('keydown', this._keyListener.bind(this));
    HotlineBus.$on('scroll-chat-bottom', () => {
      if (this.$refs['scrollChat']) {
        const vs = this.$refs['scrollChat'];
        const panel = vs.scrollPanelElm;
        const x = 100 * panel.scrollWidth;
        const y = 100 * panel.scrollHeight;
        vs.scrollTo({
          x,
          y,
        });
      }
    });
  },
  beforeDestroy() {
    // Nettoyer l'écouteur d'événement lors de la destruction du composant
    this.$parent.$off('load-draft', this.loadDraft);
    document.removeEventListener('keydown', this._keyListener, true);
  },
};
</script>

<style lang="scss">
.messagerie-chat--footer--wysiwyg {
  display: grid;
  grid-template-columns: 5fr 1fr;

  .tiptap-vuetify-editor__content {
    // max-height: 250px;
    word-break: break-word;

    img {
      display: block;
      max-width: 800px;
      max-height: 400px;
      width: auto;
      height: auto;
    }
  }

  .icon-rotate {
    rotate: -40deg;
  }

  .resize > div > div > div {
    resize: vertical;
    overflow: auto;
    min-height: 120px;
    max-height: 460px;
  }
}
</style>
